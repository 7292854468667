import { NxpButtonDeleteConfirm } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { DeleteReferenceFiles } from "../reference-file-services";
import { VocSummaryReferenceFileForm } from "../reference-file-type";

interface DeleteReferenceFileProps {
  record: VocSummaryReferenceFileForm;
  onSetReferenceFiles: React.Dispatch<
    React.SetStateAction<VocSummaryReferenceFileForm[]>
  >;
}

const DeleteReferenceFile: React.FC<DeleteReferenceFileProps> = ({
  record,
  onSetReferenceFiles,
}) => {
  const { t } = useTranslation();
  const { errorHandler, serviceConfig } = useAppContext();
  const { eventId } = useParams<{ eventId: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteReferenceFile = async () => {
    if (Number(record.id)) {
      onSetReferenceFiles((prevReference) =>
        prevReference.filter((file) => file.id !== record.id)
      );
      return;
    }
    try {
      setIsLoading(true);
      await DeleteReferenceFiles(eventId, record, serviceConfig);
      onSetReferenceFiles((prevReference) =>
        prevReference.filter((file) => file.id !== record.id)
      );
    } catch (error) {
      errorHandler(error, "delete the reference file");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NxpButtonDeleteConfirm
      loading={isLoading}
      onConfirm={handleDeleteReferenceFile}
    >
      {t("voc.feedback.delete")}
    </NxpButtonDeleteConfirm>
  );
};

export default DeleteReferenceFile;
