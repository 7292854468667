import { NxpButtonDeleteConfirm } from "@nexploretechnology/nxp-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { updateKeyDates } from "../../planning-services";
import { VocGetKeyDatesResponse } from "../../planning-type";

interface DeleteImpactedDeadlineProps {
  id: string | number;
  record: Pick<
    VocGetKeyDatesResponse,
    "id" | "forecastDate" | "timeImpact" | "dateOfContract" | "reference"
  >;
  onSetImpactDeadlines: React.Dispatch<
    React.SetStateAction<
      Pick<
        VocGetKeyDatesResponse,
        "forecastDate" | "timeImpact" | "dateOfContract" | "reference" | "id"
      >[]
    >
  >;
}

const DeleteImpactedDeadline: React.FC<DeleteImpactedDeadlineProps> = ({
  id,
  record,
  onSetImpactDeadlines,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const handleDelete = async () => {
    if (typeof id === "number") {
      onSetImpactDeadlines((prev) =>
        prev.filter((deadline) => deadline.id !== record.id)
      );
      return;
    }

    const keyDatesForm = {
      records: [
        {
          id: record.id,
          isDeleted: true,
        },
      ],
    };
    try {
      await updateKeyDates(eventId, keyDatesForm, serviceConfig);
      onSetImpactDeadlines((prev) =>
        prev.filter((deadline) => deadline.id !== record.id)
      );
    } catch (error) {
      errorHandler(error, "delete impacted deadline");
    }
  };

  return (
    <NxpButtonDeleteConfirm onConfirm={handleDelete}>
      {t("voc.feedback.delete")}
    </NxpButtonDeleteConfirm>
  );
};

export default DeleteImpactedDeadline;
