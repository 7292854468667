import { UploadOutlined } from "@ant-design/icons";
import {
  NxpDatePicker,
  NxpFullFormTableColumnProps,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Button, Input, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { upload } from "../../../../../app/services/file";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import DeleteReferenceFile from "../actions/DeleteReferenceFile";
import {
  ReferenceFileFormStateItem,
  VocSummaryReferenceFileForm,
} from "../reference-file-type";

interface UseReferenceFileEditTableColumnsProps {
  referenceFiles: VocSummaryReferenceFileForm[];
  onSetReferenceFiles: Dispatch<SetStateAction<VocSummaryReferenceFileForm[]>>;
  errors: { [id: string]: { description?: string } };
  setErrors: Dispatch<
    SetStateAction<{ [id: string]: { description?: string } }>
  >;
}

const useReferenceFileEditTableColumns = ({
  onSetReferenceFiles,
  referenceFiles,
  errors,
  setErrors,
}: UseReferenceFileEditTableColumnsProps) => {
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const handleFieldChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      const updatedFiles = referenceFiles.map((file) =>
        file.id === id ? { ...file, [field]: value } : file
      );
      onSetReferenceFiles(updatedFiles);

      if (field === "description") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            description: value
              ? undefined
              : t("voc.eventPage.summary.refFile.descriptionRequired"),
          },
        }));
      }
    },
    [referenceFiles, onSetReferenceFiles, setErrors, t]
  );

  const handleFileChange = useCallback(
    async (file: RcFile, record: VocSummaryReferenceFileForm) => {
      try {
        const { assetId, url } = await upload(file, serviceConfig);
        const newFile = {
          assetId,
          url,
          fileName: file.name,
          mimeType: file.type,
        };

        onSetReferenceFiles((prevFiles) =>
          prevFiles.map((item) =>
            item.id === record.id ? { ...item, file: [newFile] } : item
          )
        );
      } catch (error) {
        errorHandler(error, t("voc.feedback.uploadError"));
      }
    },
    [serviceConfig, errorHandler, t, onSetReferenceFiles]
  );

  const handleRemoveFile = useCallback(
    (record: VocSummaryReferenceFileForm) => {
      onSetReferenceFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.id === record.id ? { ...item, file: [] } : item
        )
      );
    },
    [onSetReferenceFiles]
  );

  return useMemo<NxpFullFormTableColumnProps<ReferenceFileFormStateItem>[]>(
    () => [
      {
        title: t("voc.common.action"),
        key: "actions",
        width: "5%",
        render: (_, record) => (
          <DeleteReferenceFile
            record={record}
            onSetReferenceFiles={onSetReferenceFiles}
          />
        ),
      },
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
        render: (description, record) => (
          <div>
            <Input
              value={description}
              onChange={(e) =>
                handleFieldChange(record.id, "description", e.target.value)
              }
            />
            {errors[record.id]?.description && (
              <div style={{ color: "red", marginTop: 5 }}>
                {errors[record.id].description}
              </div>
            )}
          </div>
        ),
      },
      {
        title: t("voc.eventPage.summary.documentDate"),
        dataIndex: "documentDate",
        key: "documentDate",
        sorter: (a, b) =>
          sorterForDate(new Date(a.documentDate), new Date(b.documentDate)),
        render: (date, record) => (
          <NxpDatePicker
            format={getUserDisplayDateFormat()}
            value={date}
            allowClear={false}
            onChange={(date) =>
              handleFieldChange(record.id, "documentDate", date)
            }
          />
        ),
      },
      {
        title: t("voc.eventPage.summary.viewFile"),
        dataIndex: "file",
        key: "file",
        sorter: (a, b) =>
          sorterForString(a.file[0].fileName, b.file[0].fileName),
        render: (file, record) => {
          switch (file.length) {
            case 0:
              return (
                <Upload
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleFileChange(file, record);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />} />
                </Upload>
              );
            case 1:
              return (
                <Upload
                  fileList={record.file.map((file) => ({
                    uid: file.assetId,
                    name: file.fileName,
                    status: "done",
                    url: file.url,
                  }))}
                  onRemove={() => handleRemoveFile(record)}
                >
                  {record?.file.length < 1 && (
                    <Button icon={<UploadOutlined />} />
                  )}
                </Upload>
              );
            default:
              return null;
          }
        },
      },
      {
        title: t("voc.eventPage.summary.externalLink"),
        dataIndex: "link",
        key: "link",
        render: (link, record) => (
          <Input
            value={link}
            onChange={(e) =>
              handleFieldChange(record.id, "link", e.target.value)
            }
          />
        ),
      },
      {
        title: t("voc.eventPage.summary.comment"),
        dataIndex: "comment",
        key: "comment",
        sorter: (a, b) => sorterForString(a.comment, b.comment),
        render: (comment, record) => (
          <Input
            value={comment}
            onChange={(e) =>
              handleFieldChange(record.id, "comment", e.target.value)
            }
          />
        ),
      },
    ],
    [
      handleFieldChange,
      handleFileChange,
      handleRemoveFile,
      onSetReferenceFiles,
      errors,
      t,
    ]
  );
};

export default useReferenceFileEditTableColumns;
