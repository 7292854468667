import { NxpTable } from "@nexploretechnology/nxp-ui";
import React, { Dispatch, SetStateAction } from "react";
import useReferenceFileDisplayTableColumns from "./hooks/useReferenceFileDisplayTableColumns";
import useReferenceFileEditTableColumns from "./hooks/useReferenceFileEditTableColumns";
import { VocSummaryReferenceFileForm } from "./reference-file-type";

interface ReferenceFileListingProps {
  editing: boolean;
  referenceFiles: VocSummaryReferenceFileForm[];
  onSetReferenceFiles: Dispatch<SetStateAction<VocSummaryReferenceFileForm[]>>;
  errors: { [id: string]: { description?: string } };
  setErrors: Dispatch<
    SetStateAction<{ [id: string]: { description?: string } }>
  >;
}

const ReferenceFileListing: React.FC<ReferenceFileListingProps> = ({
  referenceFiles,
  editing,
  onSetReferenceFiles,
  errors,
  setErrors,
}) => {
  const displayColumns = useReferenceFileDisplayTableColumns();

  const editColumns = useReferenceFileEditTableColumns({
    referenceFiles,
    onSetReferenceFiles,
    errors,
    setErrors,
  });

  return (
    <NxpTable
      useBackgroundLight
      columns={editing ? editColumns : displayColumns}
      dataSource={referenceFiles}
      rowKey="id"
    />
  );
};

export default ReferenceFileListing;
