import { NxpFullFormTable, ValidationResult } from "@nexploretechnology/nxp-ui";
import React, { useCallback } from "react";
import useTaskDisplayTableColumns from "./hooks/useTaskDisplayTableColumns";
import useTaskEditTableColumns from "./hooks/useTaskEditTableColumns";
import { TaskFormStateItem, VocTask } from "./task-type";

interface TaskTableProps {
  tasks: VocTask[];
  formState: TaskFormStateItem[];
  loading: boolean;
  onSetFormState: React.Dispatch<React.SetStateAction<TaskFormStateItem[]>>;
  validationError: ValidationResult<TaskFormStateItem>;
  editing: boolean;
}

const TaskTable: React.FC<TaskTableProps> = ({
  loading,
  tasks,
  formState,
  validationError,
  onSetFormState,
  editing,
}) => {
  const handleFormStateChange = useCallback(
    (fieldName: keyof TaskFormStateItem, value: unknown, itemUuid: string) => {
      onSetFormState((prevTasks) =>
        prevTasks.map((item) =>
          item.itemUuid === itemUuid
            ? {
                ...item,
                [fieldName]: value,
                itemState: {
                  ...item.itemState,
                  modified: true,
                },
              }
            : item
        )
      );
    },
    [onSetFormState]
  );

  const displayColumns = useTaskDisplayTableColumns();

  const editColumns = useTaskEditTableColumns({
    formState,
    validationError,
    onSetFormState,
    onFormStateChange: handleFormStateChange,
  });

  return (
    <NxpFullFormTable
      rowKey="itemUuid"
      useBackgroundLight
      formState={formState}
      columns={editing ? editColumns : displayColumns}
      validationError={validationError as any} // useValidate<> cannot handle array, so just add any
      onFormStateChange={handleFormStateChange}
      hideDelete={true}
      onRowToggleDelete={undefined}
    />
  );
};

export default TaskTable;
