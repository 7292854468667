import { notify } from "@nexploretechnology/nxp-ui";
import { useCallback, useState } from "react";
import useAppContext from "../../../../../../app/hooks/useAppContext";
import { upload } from "../../../../../../app/services/file";
import {
  VocRecordStatus,
  VocSupportDocument,
  VocSupportDocumentEnums,
} from "../../../../../../app/types";
import {
  deleteSupportDocumentsById,
  editEventSupportDocuments,
  editSiteEventSupportDocuments,
} from "../../document-form-services";

export const useGeneralSupportingActions = ({
  records,
  eventId,
  siteEventId,
  onRefresh,
  onSetIsLoading,
  documentType,
}: {
  records: VocSupportDocument[];
  eventId: string;
  siteEventId: string;
  documentType: VocSupportDocumentEnums;
  onRefresh: () => void;
  onSetIsLoading: (value: boolean) => void;
}) => {
  const { serviceConfig, errorHandler } = useAppContext();

  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState<VocSupportDocument[]>([]);

  const handleClickEdit = () => {
    setFormState([...records]);
    setEditing(true);
  };

  const handleClickCancel = () => {
    setEditing(false);
    setFormState([]);
  };

  const deleteDocument = useCallback(
    async (record: VocSupportDocument) => {
      const idToUse = siteEventId || eventId;
      const eventType = siteEventId ? "site_events" : "events";
      await deleteSupportDocumentsById(
        idToUse,
        record.id,
        eventType,
        serviceConfig
      );
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    },
    [eventId, onRefresh, serviceConfig, siteEventId]
  );

  const handleSave = async () => {
    if (!formState) return;

    onSetIsLoading(true);
    const updatedLabourRecords = formState.map((item) => ({
      comment: item.comment,
      details: { ...item.details },
      documentFiles: item.documentFiles.map((file) => ({
        assetId: file.assetId,
        fileName: file.fileName,
        mimeType: file.mimeType,
        url: file.url,
      })),
      documentType: item.documentType,
      id: item.id,
    }));

    const isSiteEvent = Boolean(siteEventId);
    try {
      if (isSiteEvent) {
        await editSiteEventSupportDocuments(siteEventId, serviceConfig, {
          documentType: documentType,
          documents: updatedLabourRecords,
        });
      } else {
        await editEventSupportDocuments(eventId, serviceConfig, {
          documentType: documentType,
          documents: updatedLabourRecords,
        });
      }
      setEditing(false);
      setFormState([]);
      notify.actionCompleted();
      onRefresh();
    } catch (error) {
      errorHandler(error, "save labour records");
    } finally {
      onSetIsLoading(false);
    }
  };

  const handleRemoveFile = useCallback(
    (record) => {
      if (!formState) return;

      const updatedFormState = formState.map((item) =>
        item.id === record.id ? { ...item, documentFiles: [] } : item
      );
      setFormState(updatedFormState);
    },
    [formState]
  );

  const handleFileChange = useCallback(
    async (file, record) => {
      try {
        const { assetId, url } = await upload(file, serviceConfig);
        const newFile = {
          assetId,
          url,
          fileName: file.name,
          mimeType: file.type,
        };

        if (!formState) return;

        const updatedFormState = formState.map((item) =>
          item.id === record.id
            ? {
                ...item,
                documentFiles: [
                  {
                    assetId: newFile.assetId,
                    fileName: file.name,
                    url: newFile.url,
                    mimeType: file.type,
                  },
                ],
              }
            : item
        );
        setFormState(updatedFormState);
      } catch (error) {
        errorHandler(error, "upload file");
      }
    },
    [serviceConfig, formState, errorHandler]
  );

  const handleInputChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      if (!formState) return;

      const newGeneralRecords = formState.map((item) => {
        if (item.id === id) {
          //if recordStatus is undefined, backend will not return recordStatus
          if (field === "recordStatus") {
            return {
              ...item,
              details: {
                ...item.details,
                recordStatus: value as VocRecordStatus,
              },
            };
          }
          if (field in item.details) {
            return {
              ...item,
              details: {
                ...item.details,
                [field]: value,
              },
            };
          } else {
            return {
              ...item,
              [field]: value,
            };
          }
        }
        return item;
      });
      setFormState(newGeneralRecords);
    },
    [formState]
  );

  return {
    handleInputChange,
    handleFileChange,
    handleRemoveFile,
    handleSave,
    deleteDocument,
    handleClickCancel,
    handleClickEdit,
    editing,
    formState,
  };
};
