import { VocGetDictionary } from "../../../../../app/types";
import { VocGetKeyDatesResponse } from "../../planning-type";

export const getAvailableReferenceOptions = (
  impactDeadlines: Pick<
    VocGetKeyDatesResponse,
    "id" | "reference" | "forecastDate" | "timeImpact" | "dateOfContract"
  >[],
  currentRecordId: string | number,
  currentReference: string,
  keyDates: VocGetDictionary[]
) => {
  const usedReferences = impactDeadlines.reduce((acc, item) => {
    if (item.id !== currentRecordId) {
      acc.push(item.reference);
    }
    return acc;
  }, []);

  return keyDates
    .filter(
      (item) =>
        !usedReferences.includes(item.value) || item.value === currentReference
    )
    .map((item) => ({
      label: item.label,
      value: item.value,
    }));
};
