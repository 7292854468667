// src/hooks/useTasks.ts
import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { getEventTasks } from "../task-services";
import { VocTask } from "../task-type";

const useTasks = () => {
  const { serviceConfig, errorHandler } = useAppContext();
  const { siteEventId, eventId } = useParams<{
    siteEventId?: string;
    eventId?: string;
  }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [tasks, setTasks] = useState<VocTask[]>([]);

  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getEventTasks(
        siteEventId && !eventId ? siteEventId : eventId,
        siteEventId && !eventId ? "siteEvent" : "compEvent",
        serviceConfig
      );
      setTasks(response);
    } catch (ex) {
      errorHandler(ex, "Fetch Event Task Failure");
    } finally {
      setLoading(false);
    }
  }, [siteEventId, eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return { loading, setLoading, tasks, setTasks, fetchTasks };
};

export default useTasks;
