import { NxpButton, NxpPanel } from "@nexploretechnology/nxp-ui";
import { Switch } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import useAppContext from "../../../../app/hooks/useAppContext";
import { useValidate } from "../../../../app/hooks/useValidate";
import useTasks from "./hooks/useTasks";
import { updateCompEventTasks, updateSiteEventTasks } from "./task-services";
import { TaskFormStateItem } from "./task-type";
import TaskTable from "./TaskTable";

const useStyles = createUseStyles({
  tasksList: {
    "& .ant-collapse": {
      margin: "20px 20px 40px",
      border: "1px solid #f0f0f0",
      borderRadius: "2px",
    },
  },
  editTaskListButton: {
    "& .ant-btn": {
      margin: "0 10px",
    },
  },
});

export interface TaskPanelProps {}

const TaskPanel: React.FC<TaskPanelProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { eventId, siteEventId } = useParams();
  const { serviceConfig, errorHandler, activeUser } = useAppContext();
  const { loading, setLoading, tasks, fetchTasks } = useTasks();

  const [hideCompleted, setHideCompleted] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState<TaskFormStateItem[]>([]);

  const initFormState = useCallback(async () => {
    setFormState(
      tasks.map((item) => ({
        ...item,
        itemState: {
          new: false,
          modified: false,
          deleted: false,
        },
        itemUuid: uuidv4(),
      }))
    );
  }, [tasks]);

  useEffect(() => {
    initFormState();
  }, [initFormState]);

  const formSchema = yup.array(
    yup.object().shape({
      taskDetails: yup.string().required("task details to is required"),
      assignedTo: yup.object({
        id: yup.string().required("assigned to is required"),
      }),
      deadline: yup.string().required("deadline  is required"),
    })
  );

  const handleSaveValidated = async () => {
    try {
      setLoading(true);
      const payload = {
        tasks: formState.map((task) => ({
          taskDetails: task.taskDetails,
          assignedToId: task.assignedTo.id,
          deadline: task.deadline,
          taskStatus: task.taskStatus,
          id: task.id,
        })),
      };

      if (eventId && !siteEventId) {
        await updateCompEventTasks(eventId, payload, serviceConfig);
      } else {
        await updateSiteEventTasks(
          eventId && !siteEventId ? eventId : siteEventId,
          payload,
          serviceConfig
        );
      }

      await fetchTasks();
      setEditing(false);
    } catch (error) {
      errorHandler("save tasks:", error);
    } finally {
      setLoading(false);
      clearError();
    }
  };

  const [validationError, , clearError, saveWithValidate] =
    useValidate<TaskFormStateItem>(
      formState,
      formSchema as any,
      handleSaveValidated
    );

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const handleEditToggle = () => setEditing((prev) => !prev);

  const handleAddTask = () => {
    const now = new Date();
    const newTask: TaskFormStateItem = {
      id: Number(now),
      taskDetails: "",
      assignedToId: "",
      assignedTo: undefined,
      assignedById: activeUser.id,
      assignedBy: activeUser,
      deadline: now,
      completionDate: now,
      deleted: false,
      taskStatus: "VOC_EVENT_TASK_STATUS_01",
      createdOn: now,
      updatedOn: now,
      eventId: eventId,
      siteEventId: siteEventId,
      itemState: { new: true, modified: false, deleted: false },
      itemUuid: uuidv4(),
    };
    setFormState((prev) => [...prev, newTask]);
    setEditing(true);
  };

  const handleCancel = () => {
    fetchTasks();
    handleEditToggle();
    clearError();
  };

  const filteredTasks = useMemo(() => {
    if (hideCompleted) {
      return formState.filter(
        (task) => task.taskStatus !== "VOC_EVENT_TASK_STATUS_03"
      );
    }
    return formState;
  }, [formState, hideCompleted]);

  return (
    <div className={classes.tasksList}>
      <NxpPanel
        titleContent={t("voc.eventPage.tasks.tasks")}
        disableCollapse
        extra={
          <div className={classes.editTaskListButton}>
            <Switch
              checked={hideCompleted}
              onChange={(checked) => setHideCompleted(checked)}
            />
            {"   " + t("voc.common.hideCompleted")}
            {editing ? (
              <>
                <NxpButton type="text" onClick={handleSave} loading={loading}>
                  {t("voc.common.save")}
                </NxpButton>
                <NxpButton type="text" onClick={handleCancel}>
                  {t("voc.common.cancel")}
                </NxpButton>
                <NxpButton type="text" onClick={handleAddTask}>
                  {t("voc.common.add")}
                </NxpButton>
              </>
            ) : (
              <NxpButton type="text" onClick={handleEditToggle}>
                {t("voc.common.edit")}
              </NxpButton>
            )}
          </div>
        }
      >
        <TaskTable
          tasks={tasks}
          formState={filteredTasks}
          loading={loading}
          validationError={validationError}
          editing={editing}
          onSetFormState={setFormState}
        />
      </NxpPanel>
    </div>
  );
};

export default TaskPanel;
