import {
  notify,
  NxpButton,
  NxpPanel,
  NxpSpin,
} from "@nexploretechnology/nxp-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { mapDateToString } from "../../../../app/utils/mappers";
import { updateKeyDates } from "../planning-services";
import ImpactedDeadlineListing from "./ImpactedDeadlineListing";
import useImpactDeadline from "./useImpactDeadline";

const useStyles = createUseStyles({
  impactedDeadlinePanel: {
    margin: 20,
  },
  editButton: {
    "& .ant-btn": {
      margin: "0 10px",
    },
  },
});

const ImpactedDeadline = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { eventId } = useParams<{ eventId?: string }>();
  const { errorHandler, serviceConfig, libraries } = useAppContext();

  const {
    loading,
    setLoading,
    impactDeadlines,
    setImpactDeadlines,
    fetchImpactDeadline,
  } = useImpactDeadline();

  const [editing, setEditing] = useState(false);

  const handleEditToggle = () => {
    setEditing(true);
  };

  const [errors, setErrors] = useState<{
    [id: string]: { reference?: string };
  }>({});

  const handleSave = async () => {
    const newErrors: { [id: string]: { reference?: string } } = {};
    let hasErrors = false;
    impactDeadlines.forEach((deadline) => {
      if (!deadline.reference) {
        newErrors[deadline.id] = { reference: "Reference is required" };
        hasErrors = true;
      }
    });
    setErrors(newErrors);

    if (hasErrors) {
      return;
    }

    setEditing(false);
    const keyDatesForm = {
      records: impactDeadlines.map((deadline) => {
        if (typeof deadline.id === "number") {
          return {
            forecastDate: mapDateToString(deadline.forecastDate),
            reference: deadline.reference,
          };
        }
        return {
          id: deadline.id,
          forecastDate: mapDateToString(deadline.forecastDate),
          reference: deadline.reference,
        };
      }),
    };

    setLoading(true);
    try {
      await updateKeyDates(eventId, keyDatesForm, serviceConfig);
      notify.actionCompleted();
      fetchImpactDeadline();
    } catch (err) {
      errorHandler(err, "save impacted deadline");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    fetchImpactDeadline();
  };

  const handleAdd = () => {
    setImpactDeadlines((prev) => [
      ...prev,
      {
        forecastDate: new Date().toISOString(),
        timeImpact: 0,
        dateOfContract: "",
        reference: "",
        id: Number(new Date()),
      },
    ]);
  };

  return (
    <NxpSpin spinning={loading}>
      <NxpPanel
        className={classes.impactedDeadlinePanel}
        titleContent={t("voc.eventPage.planning.keyDatesHeader")}
        disableCollapse
        extra={
          <div className={classes.editButton}>
            {editing ? (
              <>
                <NxpButton type="text" onClick={handleSave} loading={loading}>
                  {t("voc.common.save")}
                </NxpButton>
                <NxpButton type="text" onClick={handleCancel}>
                  {t("voc.common.cancel")}
                </NxpButton>
                <NxpButton
                  type="text"
                  onClick={handleAdd}
                  disabled={
                    impactDeadlines.length === libraries["VOC_KEY_DATES"].length
                  }
                >
                  {t("voc.common.add")}
                </NxpButton>
              </>
            ) : (
              <NxpButton type="text" onClick={handleEditToggle}>
                {t("voc.common.edit")}
              </NxpButton>
            )}
          </div>
        }
      >
        <ImpactedDeadlineListing
          errors={errors}
          impactDeadlines={impactDeadlines}
          onSetImpactDeadlines={setImpactDeadlines}
          editing={editing}
          onSetErrors={setErrors}
        />
      </NxpPanel>
    </NxpSpin>
  );
};

export default ImpactedDeadline;
