// src/hooks/useTasks.ts
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { getReferenceFiles } from "../reference-file-services";
import { VocSummaryReferenceFileForm } from "../reference-file-type";

interface UseTasks {
  referenceFiles: VocSummaryReferenceFileForm[];
  setReferenceFiles: Dispatch<SetStateAction<VocSummaryReferenceFileForm[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fetchReferenceFiles: () => Promise<void>;
}

const useReferenceFile = (): UseTasks => {
  const { serviceConfig, errorHandler } = useAppContext();
  const { eventId } = useParams<{
    eventId?: string;
  }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [referenceFiles, setReferenceFiles] =
    useState<VocSummaryReferenceFileForm[]>();

  const fetchReferenceFiles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getReferenceFiles(eventId, serviceConfig);
      setReferenceFiles(
        response.results.map((file) => {
          return {
            id: file.id,
            description: file.description,
            documentDate: file.documentDate,
            file: file.file,
            link: file.link,
            comment: file.comment,
          };
        })
      );
    } catch (error) {
      errorHandler(error, "fetch reference files");
    } finally {
      setLoading(false);
    }
  }, [eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchReferenceFiles();
  }, [fetchReferenceFiles]);

  return {
    referenceFiles,
    loading,
    setLoading,
    fetchReferenceFiles,
    setReferenceFiles,
  };
};

export default useReferenceFile;
