import { Dispatch, SetStateAction, useState } from "react";
import { createUseStyles } from "react-jss";
import { Mention, MentionsInput } from "react-mentions";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { useSelectUserOptions } from "../../../../app/hooks/useSelectUserOptions";
import { postChat, postSiteEventChat } from "../chat.services";
import { VocGetChat } from "../chat.type";
import mentionInputStyle from "./mentionInputStyle";
import SendMessageButton from "./SendMessageButton";
interface ChatInputContainerProps {
  onSetChat: Dispatch<SetStateAction<VocGetChat>>;
  activeEvent: string;
  chat: VocGetChat;
}

const useStyles = createUseStyles(() => ({
  chatInputContainer: {
    display: "flex",
    alignItems: "center",
  },
  inputContainer: {
    width: "95%",
    border: "1px solid #d9d9d9",
    borderRadius: "4px",
    fontSize: "20px",
    lineHeight: "1.0",
    outline: "none",
    "&:focus-within": {
      borderColor: "#40a9ff",
      boxShadow: "0 0 5px rgba(24, 144, 255, 0.5)",
    },
    "& textarea, & input": {
      border: "none",
      outline: "none",
      width: "100%",
      fontSize: "16px",
      fontFamily: "inherit",
      backgroundColor: "transparent",
    },
  },
}));

const ChatInputContainer: React.FC<ChatInputContainerProps> = ({
  onSetChat,
  activeEvent,
  chat,
}) => {
  const classes = useStyles();
  const { errorHandler, serviceConfig } = useAppContext();
  const { userOptionsForMentions } = useSelectUserOptions();
  const { siteEventId } = useParams<{
    eventId: string;
    siteEventId: string;
  }>();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [mentions, setMentions] = useState([]);

  const handleEditMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const mentionsCopy = [...mentions];
    mentions.forEach((m, i) => {
      if (event.target.value.indexOf(m.display) === -1) {
        mentionsCopy.splice(i, 1);
      }
    });
    setMentions(mentionsCopy);
    setMessage(event.target.value);
  };

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!message.trim()) {
        return;
      }
      setIsLoading(true);
      try {
        let newMessage;
        if (
          chat.compensationEvent &&
          activeEvent === chat.compensationEvent.compensationEventId
        ) {
          newMessage = await postChat(serviceConfig, activeEvent, {
            message,
            mentions: mentions.map((mention) => mention.id),
          });
        } else {
          newMessage = await postSiteEventChat(activeEvent, serviceConfig, {
            message,
            mentions: mentions.map((mention) => mention.id),
          });
        }

        setMessage("");
        onSetChat((prev) => {
          if (
            prev.compensationEvent &&
            activeEvent === prev.compensationEvent?.compensationEventId
          ) {
            return {
              ...prev,
              compensationEvent: {
                ...prev.compensationEvent,
                chats: [...prev.compensationEvent.chats, newMessage],
              },
            };
          }
          // in site event chat and selected site event
          else if (siteEventId && siteEventId === prev.siteEvent?.siteEventId) {
            return {
              ...prev,
              siteEvent: {
                ...prev.siteEvent,
                chats: [...prev.siteEvent.chats, newMessage],
              },
            };
          }
          // in compensation event chat and selected site event
          else {
            const updatedSiteEvents = prev.siteEvents.map((siteEvent) => {
              if (siteEvent.siteEventId === activeEvent) {
                return {
                  ...siteEvent,
                  chats: [...siteEvent.chats, newMessage],
                };
              }
              return siteEvent;
            });
            return {
              ...prev,
              siteEvents: updatedSiteEvents,
            };
          }
        });
      } catch (e) {
        errorHandler(e, "send message");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const focusInput = () => {
    const input = document.getElementById("input-message");
    setTimeout(() => {
      input.focus();
    }, 1500);
  };

  return (
    <div className={classes.chatInputContainer}>
      <MentionsInput
        classNames={{
          input: classes.inputContainer,
        }}
        id="input-message"
        disabled={isLoading}
        value={message}
        style={mentionInputStyle}
        onChange={handleEditMessage}
        allowSuggestionsAboveCursor={true}
        onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
          handleKeyPress(e);
          focusInput();
        }}
        placeholder="Type a message and use @ to mention users"
      >
        <Mention
          trigger="@"
          data={userOptionsForMentions}
          onAdd={(id: string, display: string) => {
            setMentions([...mentions, { id: id, display: display }]);
          }}
          displayTransform={(id: any, display: string) => `@${display}`}
          markup={"@[__display__]"}
          style={mentionInputStyle.mention}
        />
      </MentionsInput>
      <SendMessageButton
        isLoading={isLoading}
        activeEvent={activeEvent}
        message={message}
        chat={chat}
        mentions={mentions}
        onSetIsLoading={setIsLoading}
        onSetMessage={setMessage}
        onSetChat={onSetChat}
      />
    </div>
  );
};

export default ChatInputContainer;
