import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpBackTop,
  NxpButton,
  NxpLayout,
  NxpNavbarConfigMenu,
  NxpSidebarProps,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";
import { Col, Row } from "antd";
import i18n from "i18next";

import CreateFeedbackHeaderButton from "../../../feedback/create-feedback/CreateFeedbackHeaderButton";
import CreateFeedbackModalContainer from "../../../feedback/create-feedback/CreateFeedbackModalContainer";
import useAppContext from "../../hooks/useAppContext";
import { useLogger } from "../../hooks/useLogger";
import dashboardIcon from "../../images/sidebar/sidebar-dashboard.svg";
import registerIcon from "../../images/sidebar/sidebar-register.svg";
import reportsIcon from "../../images/sidebar/sidebar-reports.svg";
import searchIcon from "../../images/sidebar/sidebar-search.svg";
import securityIcon from "../../images/sidebar/sidebar-security.svg";
import settingsIcon from "../../images/sidebar/sidebar-settings.svg";
import { getUserDisplayName } from "../../services/app";
import { patchMyUserPreference } from "../../services/user-preference-services";
import { UserLanguagePreference } from "../../utils/localisation/locale-enum";
import AppAccountSettingsButton from "./AppAccountSettingsModal/AppAccountSettingsButton";
import AppUserProfile from "./AppUserProfile";
import SuspendedUserModal from "./SuspendedUserModal";

const useStyles = createUseStyles((theme) => ({
  "@global": {
    ".ant-notification": {
      zIndex: 1_000_000,
    },
    ".ant-notification-notice-warning": {
      backgroundColor: `${theme.palette.warning} !important`,
    },
    ".ant-notification-notice-error": {
      backgroundColor: `${theme.palette.danger} !important`,
      maxHeight: 600,
      overflowY: "auto !important",
    },
    ".ant-notification-notice-success": {
      backgroundColor: `${theme.palette.success} !important`,
    },
    ".ant-notification-notice-warning p, .ant-notification-notice-error p, .ant-notification-notice-message":
      {
        color: `${theme.palette.textInverted} !important`,
      },
    ".ant-notification .ant-notification-notice .ant-notification-notice-icon-warning.anticon, .ant-notification .ant-notification-notice .ant-notification-notice-icon-success.anticon":
      {
        color: `${theme.palette.textInverted}`,
      },
    ".ms-DatePicker-goToday": {
      margin: "16px 0 0 -3px",
    },
    ".feedback-btn": {
      "&:hover, &:focus": {
        background: "transparent",
        borderColor: theme.palette.backgroundLight,
      },
      position: "fixed",
      display: "flex",
      alignItems: "center",
      height: theme.spacing(4),
      top: 26,
      right: 300,
      zIndex: 1_100,
      fontWeight: "normal",
      borderColor: theme.palette.backgroundLight,
      background: "transparent",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    "#main": {
      position: "relative",
      top: "20px",
    },
    ".nxpSidebarItem": {
      position: "relative",
      top: "20px",
    },
  },
  appLayout: {
    position: "relative",
    minHeight: "calc(100vh - 85px)",
  },
  search: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${searchIcon})`,
      transform: "scale(.9)",
    },
  },
  register: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${registerIcon})`,
    },
  },
  dashboard: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${dashboardIcon})`,
      transform: "scale(.75)",
    },
  },
  reports: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${reportsIcon})`,
      transform: "scale(.70)",
    },
  },
  security: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${securityIcon})`,
      transform: "scale(.75)",
    },
  },
  settings: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${settingsIcon})`,
    },
  },

  navBar: {},
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
    },
  },
  userPopoverButtons: {
    "& > div": {
      padding: theme.spacing(1),
      "& > button": {
        width: "100%",
      },
    },
  },
}));

interface AppLayoutProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleModalClose = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const classes = useStyles();
  const { activeEntity, activeUser, serviceConfig, hasRight } = useAppContext();
  const { keycloak } = useKeycloak();

  const logger = useLogger();

  const handleChangeLanguageConfirmed = useCallback(
    async (key: string, isRefresh: boolean) => {
      const selectedKey = key as UserLanguagePreference;
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: "entity",
        parameter: "language",
        value: selectedKey,
      });
      i18n.changeLanguage(key);
      logger.info(`Language changed to ${selectedKey}`);
      if (isRefresh) {
        // for formIO form to re-initialize
        setTimeout(() => {
          window.location.reload();
        }, 250);
      }
      return newUserPreference;
    },
    [serviceConfig, logger]
  );

  const handleChangeLanguage = useCallback(
    async (key: string) => {
      const isValueRegister =
        document.getElementsByClassName("voc-value-event-register-container")
          .length > 0;

      if (isValueRegister) {
        if (!window.confirm(t("voc.common.languageChangeRefreshWarning")))
          return;
        handleChangeLanguageConfirmed(key, true);
      } else {
        handleChangeLanguageConfirmed(key, false);
      }
    },
    [handleChangeLanguageConfirmed, t]
  );

  if (!activeUser) {
    return null;
  }

  const logoLink = {
    label: t("app.common.BackToWorkspace").toUpperCase(),
    url:
      serviceConfig.qcAppBaseUrl && serviceConfig.entityId
        ? `${serviceConfig.qcAppBaseUrl}/entities/${serviceConfig.entityId}/workspace`
        : "",
    target: "_blank" as const,
  };

  return (
    <NxpLayout
      className={classes.appLayout}
      navBarProps={{
        logoLink,
        title: "QC Event Manager",
        subtitleContent: `${activeEntity?.name} (v2.15.0)`,
        userImage: activeUser?.avatar?.url,
        username: getUserDisplayName(activeUser),
        userPopoverContent: (onPopoverVisibleChange) =>
          activeUser ? (
            <>
              <AppUserProfile user={activeUser} />
              <Row className={classes.userPopoverButtons}>
                <Col span={12}>
                  <AppAccountSettingsButton
                    onPopoverVisibleChange={onPopoverVisibleChange}
                    user={activeUser}
                  />
                </Col>
                <Col span={12}>
                  <NxpButton onClick={() => keycloak.logout()}>
                    {t("app.layout.Logout")}
                  </NxpButton>
                </Col>
              </Row>
            </>
          ) : null,
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={handleChangeLanguage}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "English (US)",
                key: "en-US",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={{
        items: [
          ...((activeEntity && hasRight("demo-view")
            ? [
                {
                  type: "submenu",
                  // href: `/entities/${activeEntity.id}/demo`,
                  title: t("app.layout.Register"),
                  className: classes.register,
                  menuLinks: [
                    {
                      href: `/entities/${activeEntity.id}/site-events`,
                      title: t("voc.sidebar.siteEvents"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/compensation-events`,
                      title: t("voc.sidebar.compensationEvents"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/values`,
                      title: t("voc.sidebar.valueRegister"),
                    },
                  ],
                },
                {
                  type: "submenu",
                  href: `/entities/${activeEntity.id}/reports`,
                  title: t("app.layout.Reports"),
                  className: classes.reports,
                  menuLinks: [
                    {
                      href: `/entities/${activeEntity.id}/dashboard`,
                      title: t("voc.common.dashboard"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/variance/report/page`,
                      title: t("voc.common.reports"),
                    },
                  ],
                },
                {
                  type: "submenu",
                  href: `/entities/${activeEntity.id}/administration`,
                  title: t("app.layout.Administration"),
                  className: classes.security,
                  menuLinks: [
                    {
                      href: `/entities/${activeEntity.id}/libraries`,
                      title: t("voc.sidebar.libraries"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/admin/customization`,
                      title: t("voc.sidebar.customization"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/admin/access`,
                      title: t("voc.sidebar.access"),
                    },
                    {
                      href: `/entities/${activeEntity.id}/admin/roles`,
                      title: t("voc.sidebar.roles"),
                    },
                    hasRight({ objectCode: "feedbacks", actionType: "reader" })
                      ? {
                          href: `/entities/${activeEntity.id}/admin/feedback`,
                          title: t("voc.sidebar.feedback"),
                        }
                      : null,
                  ],
                },
              ]
            : []) as NxpSidebarProps["items"]),
        ],
      }}
    >
      {!activeUser.isSuspended && (
        <CreateFeedbackHeaderButton setShowCreateModal={setShowCreateModal} />
      )}
      {showCreateModal && (
        <CreateFeedbackModalContainer
          showCreateModal={showCreateModal}
          onModalClose={handleModalClose}
        />
      )}
      {activeUser.isSuspended ? <SuspendedUserModal /> : children}
      <NxpBackTop />
    </NxpLayout>
  );
};

export default AppLayout;
