import React from "react";

import GeneralInformation from "./general-information/GeneralInformation";
import ImpactedDeadline from "./impacted-deadline/ImpactedDeadline";

const PlanningPage: React.FC = (props) => {
  return (
    <>
      <GeneralInformation />
      <ImpactedDeadline />
    </>
  );
};

export default PlanningPage;
