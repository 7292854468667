// useTaskTableColumns.ts
import {
  formatDate,
  NxpFullFormTableColumnProps,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { getUserDisplayName } from "../../../../../app/utils/helper/get-user-display-name";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import NotifyTaskButton from "../actions/NotifyTaskButton";
import { convertOurskyLibsNoChildren } from "../helpers/convertOurskyLibsNoChildren";
import { TaskFormStateItem, VocTask } from "../task-type";

const useTaskDisplayTableColumns = () => {
  const { t } = useTranslation();
  const { getDictionary } = useAppContext();

  const libTaskStatuses = useMemo(() => {
    return convertOurskyLibsNoChildren(
      getDictionary("VOC_EVENT_TASK_STATUS")?.members || []
    );
  }, [getDictionary]);

  return useMemo<
    NxpFullFormTableColumnProps<VocTask & TaskFormStateItem>[]
  >(() => {
    return [
      {
        title: t("voc.eventPage.tasks.tasksDetails"),
        dataIndex: "taskDetails",
        key: "taskDetails",
        width: "18%",
        sorter: (a, b) => sorterForString(a.taskDetails, b.taskDetails),
      },
      {
        title: t("voc.eventPage.tasks.assignedTo"),
        dataIndex: "assignedTo",
        key: "assignedTo",
        width: "15%",
        sorter: (a, b) =>
          sorterForString(
            getUserDisplayName(a.assignedTo, true, true),
            getUserDisplayName(b.assignedTo, true, true)
          ),
        render: (assignedTo) => (
          <>{getUserDisplayName(assignedTo, true, true)}</>
        ),
      },
      {
        title: t("voc.eventPage.tasks.deadline"),
        dataIndex: "deadline",
        key: "deadline",
        width: "13%",
        sorter: (a, b) =>
          sorterForDate(new Date(a.deadline), new Date(b.deadline)),
        render: (deadline: string) =>
          formatDate(new Date(deadline), getUserDisplayDateFormat()),
      },
      {
        title: t("voc.eventPage.tasks.assignedBy"),
        dataIndex: "assignedBy",
        key: "assignedBy",
        width: "15%",
        sorter: (a, b) =>
          sorterForString(
            getUserDisplayName(a.assignedBy, true, true),
            getUserDisplayName(b.assignedBy, true, true)
          ),
        render: (assignedBy) => (
          <>{getUserDisplayName(assignedBy, true, true)}</>
        ),
      },
      {
        title: t("voc.eventPage.tasks.assignedDate"),
        dataIndex: "createdOn",
        key: "createdOn",
        width: "13%",
        sorter: (a, b) =>
          sorterForDate(new Date(a.createdOn), new Date(b.createdOn)),
        render: (createdOn: string) =>
          formatDate(new Date(createdOn), getUserDisplayDateFormat()),
      },
      {
        title: t("voc.eventPage.tasks.status"),
        dataIndex: "taskStatus",
        key: "taskStatus",
        width: "15%",
        sorter: (a, b) => sorterForString(a.taskStatus, b.taskStatus),
        render: (taskStatus: string) => (
          <span>
            {libTaskStatuses.find((item) => item.enumValue === taskStatus)
              ?.render || taskStatus}
          </span>
        ),
      },
      {
        title: t("voc.eventPage.tasks.completedDate"),
        dataIndex: "completedDate",
        key: "completedDate",
        width: "13%",
        render: (completionDate: string, record: VocTask) =>
          record.taskStatus === "VOC_EVENT_TASK_STATUS_03" ? (
            formatDate(
              new Date(record.completionDate),
              getUserDisplayDateFormat()
            )
          ) : (
            <NotifyTaskButton task={record} loading={false} />
          ),
      },
    ];
  }, [libTaskStatuses, t]);
};

export default useTaskDisplayTableColumns;
