const mentionInputStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
    borderRadius: 4,
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      borderRadius: 4,
      "&:focus-visible": {
        border: "1px solid red",
      },
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,
    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      borderRadius: 4,
      border: "1px solid #1a6da1",
      "&:focus-visible": {
        border: "1px solid red",
      },
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      height: "200px",
      overflow: "scroll",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },

  mention: {
    backgroundColor: "#cee4e5",
    borderRadius: 4,
    padding: 2,
  },
};

export default mentionInputStyle;
