import { formatDate } from "@nexploretechnology/nxp-ui";
import { VocEvent } from "../../../../app/types";
import {
  apiRequest,
  ServiceConfig,
} from "../../../../app/utils/backend/api-request";
import { getUserDisplayDateFormat } from "../../../../app/utils/mappers";
import {
  VocSendTaskReminderResponse,
  VocTask,
  VocUpdateTasks,
} from "./task-type";

export const sendTaskReminder = (
  taskId: string | number, // follow old code
  serviceConfig: ServiceConfig
): Promise<VocSendTaskReminderResponse> => {
  return apiRequest<VocSendTaskReminderResponse>(
    `/tasks/${taskId}/message/send`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
    }
  );
};

export const getEventTasks = (
  eventId: VocEvent["eventId"],
  type: "compEvent" | "siteEvent",
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  return apiRequest<VocTask[]>(
    `/${type === "compEvent" ? "events" : "site_events"}/${eventId}/tasks`,
    serviceConfig
  );
};
export const updateCompEventTasks = (
  eventId: VocEvent["eventId"],
  form: VocUpdateTasks,
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  const formattedTasks = form.tasks.map((task) => {
    const { id, ...rest } = task;

    const taskWithoutId = typeof id === "number" ? rest : { id, ...rest };

    if ("deadline" in task) {
      const formattedTask = {
        ...taskWithoutId,
        deadline: formatDate(
          new Date(task.deadline),
          getUserDisplayDateFormat()
        ),
      };
      return formattedTask;
    }
    return taskWithoutId;
  });

  const formattedBody = {
    ...form,
    tasks: formattedTasks,
  };

  return apiRequest<VocTask[]>(`/events/${eventId}/tasks`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: JSON.stringify(formattedBody),
  });
};

export const updateSiteEventTasks = (
  eventId: VocEvent["eventId"],
  form: VocUpdateTasks,
  serviceConfig: ServiceConfig
): Promise<VocTask[]> => {
  const formattedTasks = form.tasks.map((task) => {
    const { id, ...rest } = task;

    const taskWithoutId = typeof id === "number" ? rest : { id, ...rest };

    if ("deadline" in task) {
      const formattedTask = {
        ...taskWithoutId,
        deadline: formatDate(
          new Date(task.deadline),
          getUserDisplayDateFormat()
        ),
      };
      return formattedTask;
    }
    return taskWithoutId;
  });

  const formattedBody = {
    ...form,
    tasks: formattedTasks,
  };

  return apiRequest<VocTask[]>(`/site_events/${eventId}/tasks`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: JSON.stringify(formattedBody),
  });
};
