import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { getChat, getSiteEventChat } from "../chat.services";
import { VocGetChat } from "../chat.type";

const useChat = (setActiveEvent) => {
  const { eventId, siteEventId } = useParams<{
    eventId: string;
    siteEventId: string;
  }>();
  const { serviceConfig } = useAppContext();

  const [chat, setChat] = useState<VocGetChat>();

  const fetchChatMessages = useCallback(async () => {
    if (siteEventId) {
      const response = await getSiteEventChat(siteEventId, serviceConfig);
      setChat(response);
      setActiveEvent(response?.siteEvent.siteEventId);
    } else {
      const response = await getChat(eventId, serviceConfig);
      setChat(response);
      setActiveEvent(response?.compensationEvent?.compensationEventId);
    }
  }, [eventId, serviceConfig, setActiveEvent, siteEventId]);

  useEffect(() => {
    fetchChatMessages();
  }, [fetchChatMessages]);

  return { chat, fetchChatMessages, setChat };
};

export default useChat;
