// useTaskTableColumns.ts
import {
  formatDate,
  NxpDatePicker,
  NxpFullFormTableColumnProps,
  sorterForDate,
  sorterForString,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Select } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { useSelectUserOptions } from "../../../../../app/hooks/useSelectUserOptions";
import { getUserDisplayName } from "../../../../../app/utils/helper/get-user-display-name";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import DeleteTaskButton from "../actions/DeleteTask";
import NotifyTaskButton from "../actions/NotifyTaskButton";
import { convertOurskyLibsNoChildren } from "../helpers/convertOurskyLibsNoChildren";
import { TaskFormStateItem, VocTask } from "../task-type";

interface UseTaskTableColumnsProps {
  formState: TaskFormStateItem[];
  validationError: ValidationResult<TaskFormStateItem>;
  onSetFormState: React.Dispatch<React.SetStateAction<TaskFormStateItem[]>>;
  onFormStateChange: (
    fieldName: keyof TaskFormStateItem,
    value: unknown,
    itemUuid: string
  ) => void;
}

const useStyles = createUseStyles((theme) => ({
  validationError: {
    color: "#FF4D4F", //theme danger color is not available
    margin: 0,
    fontSize: "14px",
  },
  userSelect: {
    width: "100%",
  },
}));

const useTaskEditTableColumns = ({
  formState,
  validationError,
  onSetFormState,
  onFormStateChange,
}: UseTaskTableColumnsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getDictionary } = useAppContext();
  const { getUserOptionsForSelect } = useSelectUserOptions();

  const libTaskStatuses = useMemo(() => {
    return convertOurskyLibsNoChildren(
      getDictionary("VOC_EVENT_TASK_STATUS")?.members || []
    );
  }, [getDictionary]);

  return useMemo<
    NxpFullFormTableColumnProps<VocTask & TaskFormStateItem>[]
  >(() => {
    return [
      {
        title: t("Actions"),
        key: "actions",
        width: "5%",
        render: (_, record) => (
          <DeleteTaskButton
            id={record.id}
            record={record}
            onSetFormState={onSetFormState}
          />
        ),
      },
      {
        title: t("voc.eventPage.tasks.tasksDetails"),
        dataIndex: "taskDetails",
        key: "taskDetails",
        width: "18%",
        sorter: (a, b) => sorterForString(a.taskDetails, b.taskDetails),
        formItemProps: {
          controlType: "input",
        },
      },
      {
        title: t("voc.eventPage.tasks.assignedTo"),
        dataIndex: "assignedTo",
        key: "assignedTo",
        width: "15%",
        sorter: (a, b) =>
          sorterForString(
            getUserDisplayName(a.assignedTo, true, true),
            getUserDisplayName(b.assignedTo, true, true)
          ),
        render: (assignedTo, record, idx) => {
          const error = validationError[idx]?.assignedTo?.id;
          return (
            <>
              <Select
                className={classes.userSelect}
                value={assignedTo?.id || undefined}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  onFormStateChange(
                    "assignedTo",
                    { id: value },
                    record.itemUuid
                  );
                }}
                options={getUserOptionsForSelect(
                  assignedTo ? [assignedTo] : []
                )}
                placeholder={t("voc.common.selectUser")}
              />
              {error && <p className={classes.validationError}>{error}</p>}
            </>
          );
        },
      },
      {
        title: t("voc.eventPage.tasks.deadline"),
        dataIndex: "deadline",
        key: "deadline",
        width: "13%",
        sorter: (a, b) =>
          sorterForDate(new Date(a.deadline), new Date(b.deadline)),
        render: (deadline: string, record: TaskFormStateItem, idx) => {
          const error = validationError[idx]?.deadline;
          return (
            <>
              <NxpDatePicker
                format={getUserDisplayDateFormat()}
                value={new Date(deadline)}
                onChange={(value) =>
                  onFormStateChange("deadline", value, record.itemUuid)
                }
              />
              {error && <p className={classes.validationError}>{error}</p>}
            </>
          );
        },
      },
      {
        title: t("voc.eventPage.tasks.assignedBy"),
        dataIndex: "assignedBy",
        key: "assignedBy",
        width: "15%",
        render: (assignedBy) => (
          <>{getUserDisplayName(assignedBy, true, true)}</>
        ),
      },
      {
        title: t("voc.eventPage.tasks.assignedDate"),
        dataIndex: "createdOn",
        key: "createdOn",
        width: "13%",
        sorter: (a, b) =>
          sorterForDate(new Date(a.createdOn), new Date(b.createdOn)),
        render: (createdOn: string) =>
          formatDate(new Date(createdOn), getUserDisplayDateFormat()),
      },
      {
        title: t("voc.eventPage.tasks.status"),
        dataIndex: "taskStatus",
        key: "taskStatus",
        width: "10%",
        sorter: (a, b) => sorterForString(a.taskStatus, b.taskStatus),
        render: (status: string, record: TaskFormStateItem) => (
          <Select
            className={classes.userSelect}
            value={status || undefined}
            options={libTaskStatuses.map((item) => ({
              value: item.enumValue,
              label: item.render,
            }))}
            allowClear={false}
            onChange={(value) => {
              onFormStateChange("taskStatus", value, record.itemUuid);
            }}
          />
        ),
      },
      {
        title: t("voc.eventPage.tasks.completedDate"),
        dataIndex: "completedDate",
        key: "completedDate",
        width: "10%",
        render: (completedDate: string, record: VocTask) => {
          if (record.taskStatus === "VOC_EVENT_TASK_STATUS_03") {
            const date = record.completionDate
              ? new Date(record.completionDate)
              : new Date();
            return formatDate(date, getUserDisplayDateFormat());
          }

          return <NotifyTaskButton task={record} loading={false} />;
        },
      },
    ];
  }, [
    classes.userSelect,
    classes.validationError,
    validationError,
    libTaskStatuses,
    getUserOptionsForSelect,
    onFormStateChange,
    onSetFormState,
    t,
  ]);
};

export default useTaskEditTableColumns;
