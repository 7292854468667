import React from "react";
import AccessVisible from "../../../app/components/app-access-visible/AppAccessVisible";
import { VocCustomUi, VocEvent } from "../../../app/types";
import NotifyButton from "./notify/NotifyButton";
import ReferenceFile from "./reference-file/ReferenceFile";
import TaskPanel from "./tasks/TaskPanel";
import UpperCards from "./upper-cards";

interface SummaryTabProps {
  event: VocEvent;
  customUi: VocCustomUi;
  onEventRefresh: () => void;
}

const SummaryTab: React.FC<SummaryTabProps> = ({
  event,
  customUi,
  onEventRefresh,
}) => {
  return (
    <div className="summary-container">
      <AccessVisible objectCode="compensation_event" actionType="edit">
        <NotifyButton />
      </AccessVisible>
      <UpperCards
        onEventRefresh={onEventRefresh}
        vocEvent={event}
        customUi={customUi}
      />
      <ReferenceFile event={event} />
      <TaskPanel />
    </div>
  );
};

export default SummaryTab;
