import { Route } from "react-router-dom";

import AppProtectedPage from "../app/components/app-router/AppProtectedPage";
import EventDetailsContainer from "./pages/event-details/EventDetailsContainer";
import EventListContainer from "./pages/event-list/EventListContainer";
import ValueRegisterContainer from "./pages/valueRegister/ValueRegisterContainer";

export const compensationEventRoutes = [
  <Route
    key="compensation-event-list"
    path="/entities/:entityId/compensation-events"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<EventListContainer />}
        accessRight={{ objectCode: "compensation_event", actionType: "view" }}
      />
    }
  />,
  <Route
    key="compensation-event-details"
    path="/entities/:entityId/compensation-events/:eventId"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<EventDetailsContainer />}
        accessRight={{ objectCode: "compensation_event", actionType: "view" }}
      />
    }
  />,
  <Route
    key="values-register"
    path="/entities/:entityId/values"
    element={
      <AppProtectedPage
        requireEntityReady
        component={<ValueRegisterContainer />}
        accessRight={{ objectCode: "compensation_event", actionType: "view" }}
      />
    }
  />,
];
