import { convertToJsonBody } from "../../../app/services/convert-to-json-body";
import {
  apiRequest,
  ServiceConfig,
} from "../../../app/utils/backend/api-request";
import { VocChatMessage, VocGetChat, VocPostChatRequest } from "./chat.type";

export const getChat = (
  eventId: string,
  serviceConfig: ServiceConfig
): Promise<VocGetChat> => {
  return apiRequest<VocGetChat>(`/events/${eventId}/chats`, serviceConfig);
};

export const getSiteEventChat = (
  siteEventId: string,
  serviceConfig: ServiceConfig
): Promise<VocGetChat> => {
  return apiRequest<VocGetChat>(
    `/site_events/${siteEventId}/chats`,
    serviceConfig
  );
};

export const postSiteEventChat = (
  siteEventId: string,
  serviceConfig: ServiceConfig,
  post: VocPostChatRequest
): Promise<VocChatMessage> => {
  return apiRequest<VocChatMessage>(
    `/site_events/${siteEventId}/chats`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "POST",
      body: convertToJsonBody(post, serviceConfig.timezoneHourAdjustment),
    }
  );
};

export const postChat = (
  serviceConfig: ServiceConfig,
  eventId: string,
  post: VocPostChatRequest
): Promise<VocChatMessage> => {
  return apiRequest<VocChatMessage>(`/events/${eventId}/chats`, serviceConfig, {
    credentials: "same-origin",
    method: "POST",
    body: convertToJsonBody(post, serviceConfig.timezoneHourAdjustment),
  });
};
