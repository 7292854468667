import { SendOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { postChat, postSiteEventChat } from "../chat.services";
import { VocChatMention, VocGetChat } from "../chat.type";

interface SendMessageButtonProps {
  onSetMessage: Dispatch<SetStateAction<string>>;
  message: string;
  mentions: VocChatMention[];
  onSetChat: Dispatch<SetStateAction<VocGetChat>>;
  activeEvent: string;
  chat: VocGetChat;
  onSetIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({
  onSetMessage,
  message,
  mentions,
  onSetChat,
  activeEvent,
  chat,
  isLoading,
  onSetIsLoading,
}) => {
  const { errorHandler, serviceConfig } = useAppContext();
  const { siteEventId } = useParams<{
    eventId: string;
    siteEventId: string;
  }>();

  const sendMessage = async () => {
    try {
      if (!message.trim()) {
        return;
      }
      onSetIsLoading(true);
      let newMessage;
      if (
        chat.compensationEvent &&
        activeEvent === chat.compensationEvent.compensationEventId
      ) {
        newMessage = await postChat(serviceConfig, activeEvent, {
          message,
          mentions: mentions.map((mention) => mention.id),
        });
      } else {
        newMessage = await postSiteEventChat(activeEvent, serviceConfig, {
          message,
          mentions: mentions.map((mention) => mention.id),
        });
      }

      onSetMessage("");
      onSetChat((prev) => {
        if (
          prev.compensationEvent &&
          activeEvent === prev.compensationEvent.compensationEventId
        ) {
          return {
            ...prev,
            compensationEvent: {
              ...prev.compensationEvent,
              chats: [...prev.compensationEvent.chats, newMessage],
            },
          };
        } else if (siteEventId && siteEventId === prev.siteEvent?.siteEventId) {
          return {
            ...prev,
            siteEvent: {
              ...prev.siteEvent,
              chats: [...prev.siteEvent.chats, newMessage],
            },
          };
        } else {
          const updatedSiteEvents = prev.siteEvents.map((siteEvent) => {
            if (siteEvent.siteEventId === activeEvent) {
              return {
                ...siteEvent,
                chats: [...siteEvent.chats, newMessage],
              };
            }
            return siteEvent;
          });

          return {
            ...prev,
            siteEvents: updatedSiteEvents,
          };
        }
      });
    } catch (e) {
      errorHandler(e, "send message");
    } finally {
      onSetIsLoading(false);
    }
  };

  return (
    <NxpButton
      icon={<SendOutlined />}
      onClick={sendMessage}
      loading={isLoading}
    />
  );
};

export default SendMessageButton;
