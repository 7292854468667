import {
  formatDate,
  NxpDatePicker,
  NxpTable,
  NxpTableColumnProps,
  sorterForDate,
  sorterForNumber,
} from "@nexploretechnology/nxp-ui";
import { Select } from "antd";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useAppContext from "../../../../app/hooks/useAppContext";
import { getUserDisplayDateFormat } from "../../../../app/utils/mappers";
import { VocGetKeyDatesResponse } from "../planning-type";
import DeleteImpactedDeadline from "./actions/DeleteImpactedDeadline";
import { calculateImpactDate } from "./helpers/calculateImpactDate";
import { getAvailableReferenceOptions } from "./helpers/getAvailableReferenceOptions";

interface ImpactedDeadlineListingProps {
  editing: boolean;
  impactDeadlines: Pick<
    VocGetKeyDatesResponse,
    "forecastDate" | "timeImpact" | "dateOfContract" | "reference" | "id"
  >[];
  onSetImpactDeadlines: Dispatch<
    SetStateAction<
      Pick<
        VocGetKeyDatesResponse,
        "forecastDate" | "timeImpact" | "dateOfContract" | "reference" | "id"
      >[]
    >
  >;
  errors: {
    [id: string]: {
      reference?: string;
    };
  };
  onSetErrors: Dispatch<
    SetStateAction<{
      [id: string]: { reference?: string };
    }>
  >;
}

const useStyles = createUseStyles({
  referenceSelect: {
    width: "100%",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },
});

const ImpactedDeadlineListing: React.FC<ImpactedDeadlineListingProps> = ({
  impactDeadlines,
  editing,
  onSetImpactDeadlines,
  errors,
  onSetErrors,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { libraries } = useAppContext();

  const handleFieldChange = useCallback(
    (id: string | number, field: string, value: unknown) => {
      const updatedImpactDeadlines = impactDeadlines.map((deadline) =>
        deadline.id === id ? { ...deadline, [field]: value } : deadline
      );
      onSetImpactDeadlines(updatedImpactDeadlines);

      if (field === "reference") {
        onSetErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            reference: value ? undefined : "reference is required",
          },
        }));
      }
    },
    [impactDeadlines, onSetImpactDeadlines, onSetErrors]
  );

  const columns: NxpTableColumnProps<
    Pick<
      VocGetKeyDatesResponse,
      "forecastDate" | "timeImpact" | "dateOfContract" | "reference" | "id"
    >
  >[] = [
    ...(editing
      ? [
          {
            title: t("Actions"),
            key: "actions",
            width: "5%",
            render: (_, record) => (
              <DeleteImpactedDeadline
                id={record.id}
                record={record}
                onSetImpactDeadlines={onSetImpactDeadlines}
              />
            ),
          },
        ]
      : []),
    {
      title: t("voc.common.reference"),
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => {
        const labelA =
          libraries["VOC_KEY_DATES"].find((item) => item.value === a.reference)
            ?.label || "";
        const labelB =
          libraries["VOC_KEY_DATES"].find((item) => item.value === b.reference)
            ?.label || "";
        return labelA.localeCompare(labelB);
      },
      render: (reference: string, record) => {
        const keyDate = libraries["VOC_KEY_DATES"].find(
          (item) => item.value === reference
        );

        const isError = editing && !reference && errors[record.id]?.reference;
        return editing ? (
          <>
            <Select
              className={classes.referenceSelect}
              options={getAvailableReferenceOptions(
                impactDeadlines,
                record.id,
                reference,
                libraries["VOC_KEY_DATES"]
              )}
              value={reference}
              onChange={(value) => {
                handleFieldChange(record.id, "reference", value);
              }}
            />
            {isError && (
              <div className={classes.errorMessage}>
                {errors[record.id]?.reference}
              </div>
            )}
          </>
        ) : (
          <> {keyDate?.label} </>
        );
      },
    },
    {
      title: t("voc.eventPage.planning.dateOfContract"),
      dataIndex: "dateOfContract",
      key: "dateOfContract",
      sorter: (a, b) =>
        sorterForDate(new Date(a.dateOfContract), new Date(b.dateOfContract)),
      render: (dateOfContract: string, record) => {
        const libraryDates = libraries["VOC_KEY_DATES"] || [];
        const foundItem = libraryDates.find(
          (item) => item.value === record.reference
        );

        const contractualDate = foundItem ? foundItem.contractualDate : null;

        if (contractualDate) {
          return formatDate(
            new Date(contractualDate),
            getUserDisplayDateFormat()
          );
        } else if (dateOfContract) {
          return formatDate(
            new Date(dateOfContract),
            getUserDisplayDateFormat()
          );
        } else {
          return "";
        }
      },
    },
    {
      title: t("voc.eventPage.planning.dateOfForecast"),
      dataIndex: "forecastDate",
      key: "forecastDate",
      sorter: (a, b) =>
        sorterForDate(new Date(a.forecastDate), new Date(b.forecastDate)),
      render: (forecastDate: string, record) => {
        return editing ? (
          <NxpDatePicker
            value={forecastDate ? new Date(forecastDate) : null}
            format={getUserDisplayDateFormat()}
            allowClear={false}
            onChange={(date) => {
              handleFieldChange(record.id, "forecastDate", date);
            }}
          />
        ) : (
          formatDate(new Date(forecastDate), getUserDisplayDateFormat())
        );
      },
    },
    {
      title: t("voc.eventPage.planning.timeImpact"),
      dataIndex: "timeImpact",
      key: "timeImpact",
      sorter: (a, b) => sorterForNumber(a.timeImpact, b.timeImpact),
      render: (_: string, record) => {
        const timeImpact = calculateImpactDate(
          record.dateOfContract,
          record.forecastDate
        );
        return record.dateOfContract ? `${timeImpact} days` : "";
      },
    },
  ];

  return (
    <NxpTable
      rowKey={(record) => record.id}
      useBackgroundLight
      columns={columns}
      dataSource={impactDeadlines}
    />
  );
};

export default ImpactedDeadlineListing;
