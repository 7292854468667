import { formatDate } from "@nexploretechnology/nxp-ui";
import { Tag } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import useAppContext from "../../../../app/hooks/useAppContext";
import { getUserDisplayName } from "../../../../app/utils/helper/get-user-display-name";
import { getUserDisplayDateTimeFormat } from "../../../../app/utils/mappers";
import { VocChatMessage } from "../chat.type";
import { getColorForUser, getInitials } from "../helpers/chat.helper";

const useStyles = createUseStyles((theme) => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
    margin: "12px 0",
  },
  avatar: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    backgroundColor: theme.palette.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    color: "white",
  },
  messageText: {
    minWidth: "10%",
    borderRadius: "12px",
    marginLeft: "8px",
    position: "relative",
    padding: "10px",
    minHeight: "15px",
    backgroundColor: "#E8E8E8",
    wordWrap: "break-word",
    whiteSpace: "pre-line",
    maxWidth: "40%",
    lineBreakMode: "no-wrap",
    "& .ant-btn": {
      margin: "1px",
    },
  },
  subscript: {
    textAlign: "end",
    fontSize: 10,
  },
  right: {
    flexDirection: "row-reverse",
    "& $messageText": {
      backgroundColor: "#D1E8FF",
    },
    "& $avatar": {
      marginRight: "10px",
      marginLeft: "10px",
    },
    "& $bubble": {
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  left: {
    flexDirection: "row",
    "& $messageText": {
      backgroundColor: "#E8E8E8",
    },
    "& $avatar": {
      marginLeft: "10px",
      marginRight: "0",
    },
    "& $bubble": {
      marginLeft: "10px",
    },
  },
  bubble: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    backgroundColor: theme.palette.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    color: "white",
  },
}));

interface ChatMessageProps {
  messages: VocChatMessage[];
}

const ChatMessage: React.FC<ChatMessageProps> = ({ messages }) => {
  const classes = useStyles();

  const { activeUser } = useAppContext();
  const renderMessageContent = (message: string) => {
    const mentionRegex = /@\[([^\]<]+)\]/g;
    const parts = message.split(mentionRegex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a mention part
        return (
          <Tag key={index} color="geekblue">
            @{part}
          </Tag>
        );
      }
      return part;
    });
  };
  return messages?.map((message, index) => (
    <div
      key={message.id}
      className={`${classes.messageContainer} ${
        message.author?.id === activeUser?.id ? classes.right : classes.left
      }`}
    >
      <div
        className={classes.bubble}
        style={
          message.author?.id === activeUser?.id
            ? { backgroundColor: "rgb(0, 87, 148)" }
            : { backgroundColor: getColorForUser(message.author?.id ?? "") }
        }
      >
        {getInitials({
          firstName: message.author?.firstName,
          lastName: message.author?.lastName,
        })}
      </div>
      <div className={classes.messageText}>
        {renderMessageContent(message.message)}
        <div className={classes.subscript}>
          {getUserDisplayName(message.author, false, true)} {" - "}
          {formatDate(message.createdOn, getUserDisplayDateTimeFormat())}
        </div>
      </div>
    </div>
  ));
};

export default ChatMessage;
