import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { VocEvent } from "../../../../app/types";
import NewSupportingDocumentModal from "./NewSupportingDocumentModal";

interface NewDocumentButtonProps {
  siteEventId?: string;
  eventId?: VocEvent["id"];
  recordSummaryDocumentTypesOnly?: boolean;
  buttonCaption?: string;
  onRefresh: () => void;
}

const useStyles = createUseStyles({
  addSupportingButton: {
    position: "absolute",
    right: 0,
    marginTop: -55,
  },
});

const NewSupportingDocumentButton: React.FC<NewDocumentButtonProps> = ({
  onRefresh,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);

  const handleModalClose = () => setShowNewDocumentModal(false);

  return (
    <>
      <NxpButton
        className={classes.addSupportingButton}
        onClick={() => setShowNewDocumentModal(true)}
      >
        {t("voc.common.newDocument")}
      </NxpButton>

      {showNewDocumentModal && (
        <NewSupportingDocumentModal
          onRefresh={onRefresh}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default NewSupportingDocumentButton;
