import { NxpTable, NxpTableColumnProps } from "@nexploretechnology/nxp-ui";
import { Input, Select } from "antd";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useAppContext from "../../../../app/hooks/useAppContext";
import { useSelectUserOptions } from "../../../../app/hooks/useSelectUserOptions";
import { getUserDisplayName } from "../../../../app/utils/helper/get-user-display-name";
import { VocPlanningGeneralInformation } from "../planning-type";
const useStyles = createUseStyles((theme) => ({
  select: {
    width: "100%",
  },
}));

interface GeneralInformationTableProps {
  planningGeneralInformation: VocPlanningGeneralInformation;
  onSetPlanningGeneralInformation: Dispatch<
    SetStateAction<VocPlanningGeneralInformation>
  >;
  editing: boolean;
}

const GeneralInformationTable: React.FC<GeneralInformationTableProps> = ({
  planningGeneralInformation,
  editing,
  onSetPlanningGeneralInformation,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { libraries } = useAppContext();
  const { getUserOptionsForSelect } = useSelectUserOptions();

  const libConstrStatuses = useMemo(
    () => [
      {
        label: undefined,
        value: t("voc.common.pleaseSelect"),
      },
      ...libraries["VOC_CONSTR_STATUS"].map((item) => ({
        label: item.label,
        value: item.value,
      })),
    ],
    [libraries, t]
  );

  const handleFieldChange = (field: string, value: unknown) => {
    onSetPlanningGeneralInformation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const columns: NxpTableColumnProps<VocPlanningGeneralInformation>[] = [
    {
      title: t("voc.eventPage.planning.planningOwner"),
      dataIndex: "planningOwner",
      key: "planningOwner",
      width: "30%",
      render: (assignedTo, record, idx) => {
        if (editing) {
          return (
            <Select
              className={classes.select}
              value={assignedTo?.id || undefined}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                handleFieldChange(
                  "planningOwner",
                  value ? { id: value } : null
                );
              }}
              options={getUserOptionsForSelect(assignedTo ? [assignedTo] : [])}
              placeholder={t("voc.common.selectUser")}
            />
          );
        }
        return getUserDisplayName(assignedTo);
      },
    },
    {
      title: t("voc.eventPage.planning.impacted"),
      dataIndex: "impactedActivities",
      key: "impactedActivities",
      width: "20%",
      render: (impactedActivities, record, idx) => {
        if (editing) {
          return (
            <Input
              type="text"
              value={impactedActivities}
              onChange={(e) => {
                handleFieldChange("impactedActivities", e.target.value);
              }}
            />
          );
        }
        return impactedActivities;
      },
    },
    {
      title: t("voc.eventPage.planning.planningReference"),
      dataIndex: "planningReference",
      key: "planningReference",
      width: "20%",
      render: (planningReference, record, idx) => {
        if (editing) {
          return (
            <Input
              type="text"
              value={planningReference}
              onChange={(e) => {
                handleFieldChange("planningReference", e.target.value);
              }}
            />
          );
        }
        return planningReference;
      },
    },
    {
      title: t("voc.eventPage.planning.constructionStatus"),
      dataIndex: "constructionStatus",
      key: "constructionStatus",
      render: (constructionStatus, record, idx) => {
        if (editing) {
          return (
            <Select
              className={classes.select}
              value={constructionStatus || undefined}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                handleFieldChange("constructionStatus", value);
              }}
              options={libConstrStatuses}
              placeholder={t("voc.common.pleaseSelect")}
            />
          );
        }
        const status = libConstrStatuses.find(
          (item) => item.value === constructionStatus
        );
        return status?.label;
      },
    },
  ];

  return (
    <NxpTable
      columns={columns}
      dataSource={[planningGeneralInformation]}
      useBackgroundLight
    />
  );
};

export default GeneralInformationTable;
