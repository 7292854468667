import { notify, NxpButton, NxpTooltip } from "@nexploretechnology/nxp-ui";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { sendTaskReminder } from "../task-services";
import { VocTask } from "../task-type";

interface NotifyTaskButtonProps {
  task: VocTask;
  loading: boolean;
}

const NotifyTaskButton: React.FC<NotifyTaskButtonProps> = ({
  task,
  loading,
}) => {
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const sendReminder = useCallback(
    async (taskId: VocTask["id"]) => {
      setIsLoading(true);
      try {
        await sendTaskReminder(taskId, serviceConfig);
        notify.actionCompleted();
      } catch (error) {
        errorHandler(error, "send task reminder");
      } finally {
        setIsLoading(false);
      }
    },
    [serviceConfig, errorHandler]
  );

  const isNewRecord = typeof task.id === "number";
  const tooltipMessage = isNewRecord
    ? t("voc.eventPage.summary.tasks.tooltip.saveFirst")
    : "";

  return (
    <NxpTooltip title={tooltipMessage}>
      <NxpButton
        onClick={() => sendReminder(task.id)}
        loading={isLoading}
        disabled={task.id === "" || isNewRecord}
      >
        {t("voc.common.notify")}
      </NxpButton>
    </NxpTooltip>
  );
};

export default NotifyTaskButton;
