import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { accessControlRoutes } from "../../../access-control/access-control-routes";
import { compensationEventRoutes } from "../../../compensation-events/compensation-event-routes";
import { customizationRoutes } from "../../../customization/customization-routes";
import { feedbackRoutes } from "../../../feedback/feedback-routes";
import { librariesRoutes } from "../../../libraries/libraries-routes";
import { reportsRoutes } from "../../../reports/reports-routes";
import { siteEventRoutes } from "../../../site-events/site-event-routes";
import TranslationCheck from "../../pages/translation-check/TranslationCheck";
import App404 from "./App404";
import AppProtectedPage from "./AppProtectedPage";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
      <Routes>
        <Route path="/" element={<App404 />} />
        {customizationRoutes}
        {compensationEventRoutes}
        {siteEventRoutes}
        {librariesRoutes}
        {reportsRoutes}
        {accessControlRoutes}
        {feedbackRoutes}
        <Route
          path="/translation-check"
          element={<AppProtectedPage component={<TranslationCheck />} />}
        />
        <Route path="/*" element={<App404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
