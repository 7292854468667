// useTaskTableColumns.ts
import { LinkOutlined } from "@ant-design/icons";
import {
  formatDate,
  NxpFullFormTableColumnProps,
  NxpTooltip,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VocEventFile } from "../../../../../app/types";
import { getUserDisplayDateFormat } from "../../../../../app/utils/mappers";
import { ReferenceFileFormStateItem } from "../reference-file-type";

const useReferenceFileDisplayTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<
    NxpFullFormTableColumnProps<ReferenceFileFormStateItem>[]
  >(() => {
    return [
      {
        title: t("voc.eventPage.summary.subject"),
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => sorterForString(a.description, b.description),
      },
      {
        title: t("voc.eventPage.summary.documentDate"),
        dataIndex: "documentDate",
        key: "documentDate",
        render: (date) =>
          formatDate(new Date(date), getUserDisplayDateFormat()),
        sorter: (a, b) =>
          sorterForDate(new Date(a.documentDate), new Date(b.documentDate)),
      },
      {
        title: t("voc.eventPage.summary.viewFile"),
        dataIndex: "file",
        key: "file",
        sorter: (a, b) =>
          sorterForString(a?.file[0]?.fileName, b?.file[0]?.fileName),
        render: (files: VocEventFile[]) =>
          files?.map((file) => (
            <div key={file.assetId}>
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {file.fileName}
              </a>
            </div>
          )),
      },
      {
        title: t("voc.eventPage.summary.externalLink"),
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => sorterForString(a.link, b.link),
        render: (link: string) => {
          const getClickableLink = () => {
            return link.startsWith("http://") || link.startsWith("https://")
              ? link
              : `https://${link}`;
          };
          return link ? (
            <NxpTooltip title={link}>
              <a
                href={getClickableLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkOutlined />
              </a>
            </NxpTooltip>
          ) : null;
        },
      },
      {
        title: t("voc.eventPage.summary.comment"),
        dataIndex: "comment",
        key: "comment",
        sorter: (a, b) => sorterForString(a.comment, b.comment),
      },
    ];
  }, [t]);
};

export default useReferenceFileDisplayTableColumns;
