import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import NewSupportingRecordModal from "./NewSupportingRecordModal";

interface NewSupportingRecordButtonProps {
  onRefresh: () => void;
}
const useStyles = createUseStyles({
  addRecordButton: {
    position: "absolute",
    right: 0,
    marginTop: -55,
  },
});

const NewSupportingRecordButton: React.FC<NewSupportingRecordButtonProps> = ({
  onRefresh,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);

  const handleModalClose = () => setShowNewDocumentModal(false);

  return (
    <>
      <NxpButton
        className={classes.addRecordButton}
        onClick={() => setShowNewDocumentModal(true)}
      >
        {t("voc.eventPage.records.addRecords")}
      </NxpButton>

      {showNewDocumentModal && (
        <NewSupportingRecordModal
          onRefresh={onRefresh}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default NewSupportingRecordButton;
