import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import ReminderModalContainer from "./ReminderModalContainer";

interface ReminderButtonProps {}

const useStyles = createUseStyles({
  reminderButton: {
    position: "absolute",
    right: 0,
    marginTop: -75,
  },
});

const ReminderButton: React.FC<ReminderButtonProps> = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const handleModalClose = () => setShowReminderModal(false);

  return (
    <>
      <NxpButton
        className={classes.reminderButton}
        onClick={() => setShowReminderModal(true)}
      >
        {t("voc.common.reminder")}
      </NxpButton>
      <ReminderModalContainer
        showReminderModal={showReminderModal}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default ReminderButton;
