export const getInitials = (author?: {
  firstName?: string;
  lastName?: string;
}) => {
  const firstNameInitial = author?.firstName?.[0] ?? "";
  const lastNameInitial = author?.lastName?.[0] ?? "";
  return `${firstNameInitial}${lastNameInitial}`;
};

export const getColorForUser = (userId: string | number) => {
  if (colorMap[userId]) {
    return colorMap[userId];
  }
  const unusedColors = colors.filter(
    (color) => !Object.values(colorMap).includes(color)
  );
  const colorForUser = unusedColors[0];
  colorMap[userId] = colorForUser;
  return colorForUser;
};

export const colors = [
  "#FFA500", // Orange
  "#555555", // Dark Gray
  "#777777", // Gray
  "#999999", // Light Gray
  "#333333", // Black
  "#AA3939", // Dark Red
  "#2E86C1", // Dark Blue
  "#228B22", // Dark Green
  "#BBBBBB", // Light Gray
  "#FF69B4", // Hot Pink
  "#800080", // Purple
  "#FFC0CB", // Pink
  "#00CED1", // Dark Turquoise
  "#FFD700", // Gold
  "#7CFC00", // Lawn Green
  "#FF6347", // Tomato
  "#800000", // Maroon
  "#4682B4", // Steel Blue
  "#FF8C00", // Dark Orange
  "#8B008B", // Dark Magenta
  "#6A5ACD", // Slate Blue
  "#20B2AA", // Light Sea Green
  "#6495ED", // Cornflower Blue
  "#4169E1", // Royal Blue
  "#8A2BE2", // Blue Violet
  "#A52A2A", // Brown
  "#5F9EA0", // Cadet Blue
  "#D2691E", // Chocolate
  "#DC143C", // Crimson
  "#00008B", // Dark Blue
  "#008B8B", // Dark Cyan
  "#B8860B", // Dark Golden Rod
  "#A9A9A9", // Dark Gray (alternate)
  "#006400", // Dark Green (alternate)
  "#BDB76B", // Dark Khaki
  "#8B0000", // Dark Red (alternate)
  "#E9967A", // Dark Salmon
  "#9400D3", // Dark Violet
  "#FF7F50", // Coral
  "#9ACD32", // Yellow Green
  "#ADFF2F", // Green Yellow
  "#F0E68C", // Khaki
  "#E6E6FA", // Lavender
  "#FFF0F5", // Lavender Blush
  "#7FFF00", // Chartreuse
  "#32CD32", // Lime Green
  "#FAF0E6", // Linen
  "#FF00FF", // Magenta / Fuchsia
  "#66CDAA", // Medium Aquamarine
  "#0000CD", // Medium Blue
  "#BA55D3", // Medium Orchid
  "#9370DB", // Medium Purple
  "#3CB371", // Medium Sea Green
  "#7B68EE", // Medium Slate Blue
  "#00FA9A", // Medium Spring Green
  "#48D1CC", // Medium Turquoise
  "#C71585", // Medium Violet Red
  "#191970", // Midnight Blue
  "#F5FFFA", // Mint Cream
  "#FFE4E1", // Misty Rose
  "#FFE4B5", // Moccasin
  "#FFDEAD", // Navajo White
  "#000080", // Navy
  "#FDF5E6", // Old Lace
  "#808000", // Olive
  "#6B8E23", // Olive Drab
  "#FFA07A", // Light Salmon
  "#FFA500", // Orange (alternate)
  "#FF4500", // Orange Red
  "#DA70D6", // Orchid
  "#EEE8AA", // Pale Golden Rod
  "#98FB98", // Pale Green
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FFEFD5", // Papaya Whip
  "#FFDAB9", // Peach Puff
  "#CD853F", // Peru
  "#FFC0CB", // Pink (alternate)
  "#DDA0DD", // Plum
  "#B0E0E6", // Powder Blue
  "#BC8F8F", // Rosy Brown
  "#4169E1", // Royal Blue (alternate)
];
export const colorMap: { [key: string]: string } = {};
