import { convertToJsonBody } from "../../../app/services/convert-to-json-body";
import {
  apiRequest,
  ServiceConfig,
} from "../../../app/utils/backend/api-request";
import {
  VocGetKeyDatesResponse,
  VocGetPlanningResponse,
  VocPlanningRequest,
  VocUpdateKeyDatesRequest,
} from "./planning-type";

export const getPlanning = (eventId: string, serviceConfig: ServiceConfig) => {
  return apiRequest<VocGetPlanningResponse>(
    `/events/${eventId}/planner`,
    serviceConfig
  );
};

export const updatePlanning = (
  eventId: string,
  planningForm: VocPlanningRequest,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(`/events/${eventId}/planner`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertToJsonBody(planningForm, serviceConfig.timezoneHourAdjustment),
  });
};

export const getKeyDates = (eventId: string, serviceConfig: ServiceConfig) => {
  return apiRequest<VocGetKeyDatesResponse[]>(
    `/events/${eventId}/key_dates`,
    serviceConfig
  );
};

export const updateKeyDates = (
  eventId: string,
  keyDatesForm: VocUpdateKeyDatesRequest,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(`/events/${eventId}/key_dates/batch`, serviceConfig, {
    credentials: "same-origin",
    method: "PATCH",
    body: convertToJsonBody(keyDatesForm, serviceConfig.timezoneHourAdjustment),
  });
};
