import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { VocSiteEvent, VocSiteEventPatchRequest } from "../../../app/types";
import {
  getSiteEventLogs,
  undoSiteEventLogs,
} from "../../../compensation-events/compensation-event-services";
import LogsTabContainer from "../../../compensation-events/components/logs-tab/LogsTabContainer";
import RecordsTabContainer from "../../../compensation-events/components/records-tab";
import { Recovery } from "../../site-event-services";
import InfoTabContainer from "./info-tab/InfoTabContainer";

import { NxpButton, NxpHeader, NxpTabs } from "@nexploretechnology/nxp-ui";
import ChatsTabPage from "../../../compensation-events/components/chats-tab/ChatTabPage";
import SupportingTab from "../../../compensation-events/components/supporting-tab/SupportingTab";
import "./SiteEventDetails.scss";

interface SiteEventDetailsLayoutProps {
  entityId: string;
  siteEventId: string;
  siteEventData: VocSiteEvent;
  backHref?: string;
  setForm: React.Dispatch<React.SetStateAction<VocSiteEventPatchRequest>>;
  handleEventRefresh: () => void;
  nextSiteEvent: string;
  prevSiteEvent: string;
  preAndNextEventHandler: (eventId: string) => Promise<void>;
  setUpdateSiteEventRecovery: (asyncRequest: () => Promise<Recovery>) => void;
}

const SiteEventDetailsLayout: React.FC<SiteEventDetailsLayoutProps> = (
  props
) => {
  const {
    entityId,
    siteEventId,
    siteEventData,
    backHref,
    setForm,
    handleEventRefresh,
    nextSiteEvent,
    prevSiteEvent,
    preAndNextEventHandler,
    setUpdateSiteEventRecovery,
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = React.useState<string>("info");

  const Tabs = [
    {
      key: "info",
      label: t("voc.siteEventDetails.info"),
      children: (
        <InfoTabContainer
          siteEventData={siteEventData}
          setForm={setForm}
          onEventRefresh={handleEventRefresh}
          setUpdateSiteEventRecovery={setUpdateSiteEventRecovery}
        />
      ),
    },
    {
      key: "records",
      label: t("voc.eventPage.tabRecords"),
      children: (
        <RecordsTabContainer
          event={siteEventData}
          entityId={entityId}
          eventId={null}
          siteEventId={siteEventId}
          onEventRefresh={handleEventRefresh}
          contemporaryRecordRequired={siteEventData.contemporaryRecordRequired}
        />
      ),
    },
    {
      key: "supporting",
      label: t("voc.eventPage.tabSupporting"),
      children: <SupportingTab eventDetail={siteEventData} />,
    },
    {
      key: "chats",
      label: t("voc.eventPage.tabChats"),
      children: <ChatsTabPage />,
    },
    {
      key: "logs",
      label: t("voc.eventPage.tabLogs"),
      children: (
        <LogsTabContainer
          permissionCode="event"
          id={siteEventId}
          onFetch={getSiteEventLogs}
          onUndo={undoSiteEventLogs}
          onEventRefresh={handleEventRefresh}
        />
      ),
    },
  ];

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return siteEventData ? (
    <div className="voc-event-page">
      <NxpHeader
        titleContent={siteEventData.serial + ": " + siteEventData.subject}
        onBackClick={() => navigate(backHref)}
        actionContent={
          <>
            {prevSiteEvent && (
              <NxpButton
                type="text"
                onClick={() =>
                  preAndNextEventHandler(prevSiteEvent).finally(() =>
                    navigate(`./../${prevSiteEvent}`)
                  )
                }
              >
                <span className="arrow">◀</span>{" "}
                {t("voc.siteEventDetails.prevSiteEvent")}
              </NxpButton>
            )}
            {nextSiteEvent && (
              <NxpButton
                type="text"
                onClick={() =>
                  preAndNextEventHandler(nextSiteEvent).finally(() =>
                    navigate(`./../${nextSiteEvent}`)
                  )
                }
              >
                {t("voc.siteEventDetails.nextSiteEvent")}{" "}
                <span className="arrow">▶</span>
              </NxpButton>
            )}
          </>
        }
      />
      <NxpTabs activeKey={activeTab} onChange={handleTabChange} items={Tabs} />
    </div>
  ) : null;
};

export default SiteEventDetailsLayout;
