import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import AddClaim from "./";

interface AddClaimButtonProps {
  eventId: string;
  onRefreshData: () => void;
}

const useStyles = createUseStyles({
  claimButton: {
    position: "absolute",
    right: 0,
    marginTop: -55,
  },
});

const AddClaimButton: React.FC<AddClaimButtonProps> = ({
  onRefreshData,
  eventId,
}) => {
  const classes = useStyles();
  const [showAddClaimModal, setShowAddClaimModal] = useState(false);
  const handleModalClose = () => setShowAddClaimModal(false);
  const { t } = useTranslation();
  return (
    <>
      <NxpButton
        className={classes.claimButton}
        onClick={() => setShowAddClaimModal(true)}
      >
        {t("voc.common.addClaim")}
      </NxpButton>
      {showAddClaimModal && (
        <AddClaim
          eventId={eventId}
          onRefreshData={onRefreshData}
          showAddClaimModal={showAddClaimModal}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddClaimButton;
