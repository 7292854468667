// src/hooks/useTasks.ts
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { getKeyDates } from "../planning-services";
import { VocGetKeyDatesResponse } from "../planning-type";

const useImpactDeadline = () => {
  const { serviceConfig, errorHandler } = useAppContext();
  const { eventId } = useParams<{
    eventId?: string;
  }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [impactDeadlines, setImpactDeadlines] = useState<
    Pick<
      VocGetKeyDatesResponse,
      "forecastDate" | "timeImpact" | "dateOfContract" | "reference" | "id"
    >[]
  >([]);

  const fetchImpactDeadline = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getKeyDates(eventId, serviceConfig);
      setImpactDeadlines(
        response.map((key) => {
          return {
            id: key.id,
            forecastDate: key.forecastDate,
            timeImpact: key.timeImpact, // backend not calculating time impact, so this number is not accurate
            dateOfContract: key.dateOfContract,
            reference: key.reference,
          };
        })
      );
    } catch (ex) {
      errorHandler(ex, "fetch impact deadline");
    } finally {
      setLoading(false);
    }
  }, [eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchImpactDeadline();
  }, [fetchImpactDeadline]);

  return {
    loading,
    setLoading,
    impactDeadlines,
    setImpactDeadlines,
    fetchImpactDeadline,
  };
};

export default useImpactDeadline;
