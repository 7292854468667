import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ChoiceGroup } from "office-ui-fabric-react";

import AppCard from "../../../../app/components/app-card/AppCard";
import AppDataList from "../../../../app/components/app-data-list/AppDataList";
import { DataListItem } from "../../../../app/components/app-data-list/types";
import AppFieldBlock, {
  Field,
  Option,
} from "../../../../app/components/app-field-block/AppFieldBlock";
import AppIconLink from "../../../../app/components/app-icon-link";
import {
  VocCreateCompEventData,
  VocEditingData,
  VocEventFile,
  VocEventType,
  VocSiteEvent,
  VocSiteEventValidation,
} from "../../../../app/types";
import { INSTRUCTED_BY_OTHER } from "../../../../app/utils/const";
import { convertUrl } from "../../../../app/utils/string";

import { Col, Row } from "antd";
import TaskPanel from "../../../../compensation-events/components/summary-tab/tasks/TaskPanel";
import "./InfoTab.scss";

interface InfoTabLayoutProps {
  layout: any[]; //change type later
  otherIndex: number;
  siteEventData: VocSiteEvent;
  editingData: VocEditingData;
  createCompEventData: VocCreateCompEventData;
  setCreateCompEventData: React.Dispatch<
    React.SetStateAction<VocCreateCompEventData>
  >;
  linkCompEvent: number;
  isEditingGeneral: boolean;
  setIsEditingGeneral: React.Dispatch<React.SetStateAction<boolean>>;
  isEditingRecovery: boolean;
  setIsEditingRecovery: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: Function;
  saveGeneral: Function;
  cancelGeneral: Function;
  saveRecovery: Function;
  cancelRecovery: Function;
  files: DataListItem[];
  setFiles: React.Dispatch<React.SetStateAction<DataListItem[]>>;
  setFilesUpdate: React.Dispatch<React.SetStateAction<VocEventFile[][]>>;
  addFile: Function;
  recoverable: boolean;
  setRecoverable: React.Dispatch<React.SetStateAction<boolean>>;
  reasonForNotRecoverable: string;
  setReasonForNotRecoverable: React.Dispatch<React.SetStateAction<string>>;
  compensationEvent: number;
  setCompensationEvent: React.Dispatch<React.SetStateAction<number>>;
  validationErrors: VocSiteEventValidation;
  libSources: Option[];
  libSourceTypes: Option[];
  libDelayDisrupt: Option[];
  libReps: Option[];
  libLocs: Option[];
  libStatuses: Option[];
  closeInvalid: boolean;
}

const InfoTabLayout: React.FC<InfoTabLayoutProps> = (props) => {
  const { t } = useTranslation();
  const {
    layout,
    otherIndex,
    siteEventData,
    editingData,
    createCompEventData,
    setCreateCompEventData,
    linkCompEvent,
    isEditingGeneral,
    setIsEditingGeneral,
    isEditingRecovery,
    setIsEditingRecovery,
    setValues,
    saveGeneral,
    cancelGeneral,
    saveRecovery,
    cancelRecovery,
    files,
    setFiles,
    setFilesUpdate,
    addFile,
    recoverable,
    setRecoverable,
    reasonForNotRecoverable,
    setReasonForNotRecoverable,
    compensationEvent,
    setCompensationEvent,
    validationErrors,
    libSources,
    libSourceTypes,
    libDelayDisrupt,
    libReps,
    libLocs,
    libStatuses,
    closeInvalid,
  } = props;
  const { entityId } = useParams();
  const subjectField: Field = {
    label: t("voc.eventPage.summary.subject"),
    key: "subject",
    value: editingData.subject,
    type: "string",
    width: "fourth",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const locationField: Field = {
    label: t("voc.siteEventDetails.location"),
    value: editingData.locations,
    type: "multiselectdropdown",
    key: "locations",
    options: libLocs,
    width: "fourth",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const dateOfAwarenessField: Field = {
    label: t("voc.eventPage.notifications.dateOfAwareness"),
    value: editingData.dateOfAwareness,
    type: "date",
    key: "dateOfAwareness",
    width: "fourth",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const dateOfCessationField: Field = {
    label: t("voc.eventPage.notifications.dateOfCessation"),
    value: editingData.dateOfCessation,
    type: "date",
    width: "fourth",
    key: "dateOfCessation",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const referenceField: Field = {
    label: t("voc.common.reference").toLocaleUpperCase(),
    value: editingData.reference,
    type: "string",
    width: "fourth",
    key: "reference",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
    referenceAsLink: convertUrl(editingData.reference) ? (
      <AppIconLink
        stopPropagation={true}
        href={convertUrl(editingData.reference)}
        targetBlank={true}
      >
        <div>{editingData.reference}</div>
      </AppIconLink>
    ) : (
      editingData.reference?.toString()
    ),
  };

  const sourceField: Field = {
    label: t("voc.siteEventDetails.source"),
    value: editingData.eventSource,
    type: "basicdropdown",
    options: libSources,
    width: "fourth",
    key: "eventSource",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const sourceTypeField: Field = {
    label: t("voc.siteEventDetails.sourceTypes"),
    value: editingData.eventSourceType,
    type: "multiselectdropdown",
    options: libSourceTypes,
    width: "fourth",
    key: "eventSourceType",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const delayingAndDisruptiveField: Field = {
    label: t("voc.library.titles.delayingAndDisruptive").toLocaleUpperCase(),
    value: editingData.delayDisruptiveEffect,
    type: "multiselectdropdown",
    key: "delayDisruptiveEffect",
    options: libDelayDisrupt,
    width: "fourth",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const workTypeField: Field = {
    label: t("voc.siteEventDetails.workType"),
    value: editingData.workType,
    type: "string",
    width: "fourth",
    key: "workType",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const instructedByField: Field = {
    label: t("voc.siteEventDetails.instructedBy"),
    value: editingData.instructedBy,
    type: "basicdropdown",
    options: libReps,
    width: "fourth",
    key: "instructedBy",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const instructedByOtherField: Field = {
    label: t("voc.siteEventDetails.instructedByOther"),
    value: editingData.instructedByOther,
    type: "string",
    width: "fourth",
    key: "instructedByOther",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const instructionEmpoweredField: Field = {
    label: t("voc.siteEventDetails.instructionEmpowered").toLocaleUpperCase(),
    value: editingData.instructionEmpowered ? "y" : "n",
    type: "basicdropdown",
    options: [
      {
        enumValue: "y",
        render: t("voc.common.yes"),
      },
      {
        enumValue: "n",
        render: t("voc.common.no"),
      },
    ],
    width: "fourth",
    key: "instructionEmpowered",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const tagsField: Field = {
    label: t("voc.siteEventDetails.tags"),
    value: editingData.tags,
    type: "tags",
    width: "half",
    key: "tags",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };

  const remarkField: Field = {
    label: t("voc.siteEventDetails.remark"),
    value: editingData.remarks,
    type: "textarea",
    width: "half",
    key: "remarks",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };
  const eventStatusField: Field = {
    label: "EVENT STATUS",
    value: editingData.eventStatus,
    type: "basicdropdown",
    options: libStatuses,
    width: "fourth",
    key: "eventStatus",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
    validationError: closeInvalid,
    validationErrorMessage:
      "You must set a Date of Cessation before you can close this event!",
  };

  const contemporaryRecordRequiredField: Field = {
    label: t("voc.siteEventDetails.contemporaryRecordsRequired"),
    identifier: "siteevent",
    value: editingData.contemporaryRecordRequired,
    type: "radio",
    width: "fourth",
    key: "contemporaryRecordRequired",
    onChangeCallback: setValues,
    editable: true,
    bottomBorder: true,
    sideBorder: true,
  };
  const customLayoutFields: Field[] = [];

  layout.forEach((f) => {
    if (f.display) {
      if (f.type === "QuestionListPresetField") {
        switch (f.key) {
          case "subject":
            customLayoutFields.push(subjectField);
            break;
          case "remarks":
            customLayoutFields.push(remarkField);
            break;
          case "eventSource":
            customLayoutFields.push(sourceField);
            break;
          case "eventSourceType":
            customLayoutFields.push(sourceTypeField);
            break;
          case "delayDisruptiveEffect":
            customLayoutFields.push(delayingAndDisruptiveField);
            break;
          case "reference":
            customLayoutFields.push(referenceField);
            break;
          case "locations":
            customLayoutFields.push(locationField);
            break;
          case "dateOfAwareness":
            customLayoutFields.push(dateOfAwarenessField);
            break;
          case "dateOfCessation":
            customLayoutFields.push(dateOfCessationField);
            break;
          case "workType":
            customLayoutFields.push(workTypeField);
            break;
          case "tags":
            customLayoutFields.push(tagsField);
            break;
          case "instructedBy":
            customLayoutFields.push(instructedByField);
            break;
          case "instructionEmpowered":
            customLayoutFields.push(instructionEmpoweredField);
            break;
          case "eventStatus":
            customLayoutFields.push(eventStatusField);
            break;
          case "contemporaryRecordRequired":
            customLayoutFields.push(contemporaryRecordRequiredField);
            break;
        }
      }
      if (f.type === "QuestionListCustomField") {
        let dataType: string = null;
        let stringConversionDecimals: number = null;
        let stringConversionSymbol: string = null;
        let options: Option[] = null;
        switch (f.dataType) {
          case "TEXT":
            dataType = "string";
            break;
          case "VALUE":
            dataType = "number";
            stringConversionDecimals = 2; //inject custom rounding here
            break;
          case "DATE":
            dataType = "date";
            break;
          case "PERCENTAGE":
            dataType = "number";
            stringConversionDecimals = 2; //inject custom rounding here
            stringConversionSymbol = "%";
            break;
          case "DROPDOWN":
            dataType = "basicdropdown";
            options = f.validations.limits.map((o: any) => ({
              enumValue: o,
              render: o,
            }));
            break;
          case "YESNO":
            dataType = "radio";
            break;
        }

        customLayoutFields.push({
          label: f.name.toLocaleUpperCase(),
          value: editingData["customization"][f.key],
          type: dataType,
          key: f.key,
          stringConversionDecimals: stringConversionDecimals,
          stringConversionSymbol: stringConversionSymbol,
          width: "fourth",
          onChangeCallback: setValues,
          editable: true,
          options: options,
          bottomBorder: true,
          sideBorder: true,
        });
      }
    }
  });

  const customLayoutFieldsWithOther = customLayoutFields
    .slice(0, otherIndex)
    .concat(instructedByOtherField)
    .concat(customLayoutFields.slice(otherIndex, customLayoutFields.length));
  return (
    <div className="info-tab-container">
      <AppCard
        title={t("voc.eventPage.generalInformation")}
        canEdit={true}
        isEditing={isEditingGeneral}
        editCallback={() => {
          setIsEditingGeneral(true);
        }}
        cancelCallback={() => {
          cancelGeneral();
          setIsEditingGeneral(false);
        }}
        saveCallback={() => {
          saveGeneral();
        }}
        addCallback={() => {
          addFile();
        }}
        addCallbackShortcut={true}
        // permissions={"event:edit"}
      >
        <AppFieldBlock
          entityId={entityId}
          editing={isEditingGeneral}
          fields={
            editingData.instructedBy === INSTRUCTED_BY_OTHER
              ? customLayoutFieldsWithOther
              : customLayoutFields
          }
        ></AppFieldBlock>
        <AppDataList
          editing={isEditingGeneral}
          canDeleteLastItem={true}
          columns={[
            {
              columnName: t("voc.eventPage.summary.viewFile"),
              columnWidthClassName: "remainder",
              columnDataType: "file",
              editable: true,
              noRemove: true,
            },
          ]}
          items={files}
          editSetStateHook={setFiles}
          setFiles={setFilesUpdate}
          labelIfEmpty={t("voc.common.noRecordsAvailable")}
        ></AppDataList>
      </AppCard>
      <AppCard
        title={t("voc.siteEventDetails.recovery")}
        canEdit={siteEventData.compensationEventId ? false : true}
        isEditing={isEditingRecovery}
        editCallback={() => setIsEditingRecovery(true)}
        cancelCallback={() => {
          cancelRecovery();
          setIsEditingRecovery(false);
        }}
        saveCallback={() => {
          saveRecovery();
        }}
        // permissions={"event:edit"}
      >
        <div className="radios">
          <div className="recovery">
            {isEditingRecovery ? (
              <Row justify={"start"} align={"top"}>
                <Col>
                  <ChoiceGroup
                    disabled={false}
                    required={false}
                    selectedKey={
                      recoverable ? "y" : recoverable === false ? "n" : null
                    }
                    options={[
                      {
                        text: t("voc.common.yes"),
                        key: "y",
                      },
                      {
                        text: t("voc.common.no"),
                        key: "n",
                      },
                    ]}
                    onChange={(e, selected) => {
                      if (selected.key === "y") {
                        setRecoverable(true);
                      } else if (selected.key === "n") {
                        setRecoverable(false);
                        setCompensationEvent(0);
                      }
                    }}
                    label={t("voc.siteEventDetails.clientRecovery")}
                  />
                </Col>
                {!recoverable && recoverable !== null && (
                  <Col>
                    <AppFieldBlock
                      entityId={entityId}
                      editing={isEditingRecovery}
                      fields={[
                        {
                          label: t(
                            "voc.siteEventDetails.clientRecovery.reason"
                          ),
                          value: reasonForNotRecoverable,
                          type: "textarea",
                          width: "remainder",
                          key: "reasonForNotRecoverable",
                          onChangeCallback: (_, label, reason) => {
                            setReasonForNotRecoverable(reason);
                          },
                          editable: true,
                        },
                      ]}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              <Row
                justify={"start"}
                gutter={[0, 16]}
                align={"top"}
                wrap={false}
              >
                <Col>
                  <AppFieldBlock
                    entityId={entityId}
                    editing={isEditingRecovery}
                    fields={[
                      {
                        label: t("voc.siteEventDetails.clientRecovery"),
                        value: recoverable
                          ? t("voc.common.yes")
                          : recoverable === false
                          ? t("voc.common.no")
                          : null,
                        type: "string",
                        width: "remainder",
                        key: "recoverable",
                        onChangeCallback: null,
                        editable: false,
                        validationError: validationErrors.compensationEvent
                          ? true
                          : false,
                        validationErrorMessage:
                          validationErrors.compensationEvent,
                      },
                    ]}
                  />
                </Col>
                {!recoverable && recoverable !== null && (
                  <Col>
                    <AppFieldBlock
                      entityId={entityId}
                      editing={!isEditingRecovery}
                      fields={[
                        {
                          label: t(
                            "voc.siteEventDetails.clientRecovery.reason"
                          ),
                          value: reasonForNotRecoverable,
                          type: "textarea",
                          width: "remainder",
                          key: "reasonForNotRecoverable",
                          onChangeCallback: null,
                          editable: false,
                        },
                      ]}
                    />
                  </Col>
                )}
              </Row>
            )}
          </div>
          {recoverable &&
          !siteEventData.compensationEventId &&
          !siteEventData.compensationEventSerial ? (
            <div className="compensation">
              {isEditingRecovery ? (
                <ChoiceGroup
                  disabled={false}
                  required={false}
                  selectedKey={String(compensationEvent)}
                  options={[
                    {
                      text: t("voc.siteEventDetails.newCompensationEvent"),
                      key: "1",
                    },
                    {
                      text: t("voc.siteEventDetails.linkToCompensationEvent"),
                      key: "2",
                    },
                  ]}
                  onChange={(e, option) => {
                    setCompensationEvent(Number(option.key));
                  }}
                  label={t("voc.siteEventDetails.compensationEvent")}
                />
              ) : (
                <AppFieldBlock
                  entityId={entityId}
                  editing={false}
                  fields={[
                    {
                      label: t("voc.siteEventDetails.compensationEvent"),
                      value:
                        compensationEvent === 1
                          ? t("voc.siteEventDetails.newCompensationEvent")
                          : compensationEvent === 2
                          ? t("voc.siteEventDetails.linkToCompensationEvent")
                          : "",
                      type: "string",
                      key: "compensationEvent",
                      width: "remainder",
                      onChangeCallback: null,
                      editable: false,
                    },
                  ]}
                />
              )}
            </div>
          ) : null}
          {recoverable &&
          siteEventData.compensationEventId &&
          siteEventData.compensationEventSerial ? (
            <AppFieldBlock
              entityId={entityId}
              editing={false}
              fields={[
                {
                  label: t("voc.siteEventDetails.compensationEvent"),
                  value: siteEventData.compensationEventSerial,
                  type: "string",
                  width: "remainder",
                  key: "compensationEventSerial",
                  onChangeCallback: null,
                  editable: false,
                  stringHyperlink: `../compensation-events/${siteEventData.compensationEventId}`,
                },
              ]}
            />
          ) : null}
        </div>
        {compensationEvent === 1 ? (
          <div className="compensation-event">
            <AppFieldBlock
              entityId={entityId}
              editing={isEditingRecovery}
              fields={[
                {
                  label: t("voc.common.eventType").toLocaleUpperCase(),
                  value: createCompEventData.eventType,
                  type: "basicdropdown",
                  onChangeCallback: setValues,
                  key: "eventType",
                  options: [
                    {
                      enumValue: null,
                      render: t("voc.common.pleaseSelect"),
                    },
                    {
                      enumValue: VocEventType.VO,
                      render: t("voc.common.eventType.VO_EV_TYPE_VO"),
                    },
                    {
                      enumValue: VocEventType.Claim,
                      render: t("voc.common.eventType.VO_EV_TYPE_CLAIM"),
                    },
                    {
                      enumValue: VocEventType.VO_And_Claim,
                      render: t("voc.common.eventType.VO_EV_TYPE_VO_AND_CLAIM"),
                    },
                  ],
                  width: "fourth",
                  editable: true,
                  validationError: validationErrors.eventType ? true : false,
                  validationErrorMessage: validationErrors.eventType,
                  required: true,
                },
                {
                  label: t("voc.sidebar.owner").toLocaleUpperCase(),
                  value: createCompEventData.commercialOwner,
                  key: "commercialOwner",
                  type: "owner",
                  width: "fourth",
                  onChangeCallback: setValues,
                  editable: true,
                  itemLimit: 1,
                  validationError: validationErrors.commercialOwner
                    ? true
                    : false,
                  validationErrorMessage: validationErrors.commercialOwner,
                  required: true,
                },
                {
                  label: t("voc.common.operationalOwner").toLocaleUpperCase(),
                  value: createCompEventData.operationOwner,
                  type: "owner",
                  width: "fourth",
                  key: "operationOwner",
                  onChangeCallback: setValues,
                  editable: true,
                  itemLimit: 1,
                  validationError: validationErrors.operationOwner
                    ? true
                    : false,
                  validationErrorMessage: validationErrors.operationOwner,
                  required: true,
                },
                {
                  //
                  label: t("voc.siteEventDetails.recordSupporter"),
                  value: createCompEventData.recordSupporter,
                  key: "recordSupporter",
                  type: "owner",
                  width: "fourth",
                  onChangeCallback: setValues,
                  editable: true,
                  itemLimit: 1,
                },
              ]}
            />
          </div>
        ) : null}
        {compensationEvent === 1 && isEditingRecovery ? (
          <div className="radios">
            <div className="contemporary">
              <ChoiceGroup
                disabled={false}
                required={false}
                selectedKey={
                  createCompEventData.contemporaryRecordRequired ? "y" : "n"
                }
                options={[
                  {
                    text: t("voc.common.yes"),
                    key: "y",
                  },
                  {
                    text: t("voc.common.no"),
                    key: "n",
                  },
                ]}
                onChange={() => {
                  setCreateCompEventData({
                    ...createCompEventData,
                    contemporaryRecordRequired:
                      !createCompEventData.contemporaryRecordRequired,
                  });
                }}
                label={t("voc.siteEventDetails.contemporaryRecordsRequired")}
              />
            </div>
          </div>
        ) : compensationEvent === 1 && !isEditingRecovery ? (
          <AppFieldBlock
            entityId={entityId}
            editing={false}
            fields={[
              {
                //
                label: t("voc.siteEventDetails.contemporaryRecordsRequired"),
                value: createCompEventData.contemporaryRecordRequired
                  ? t("voc.common.yes")
                  : t("voc.common.no"),
                type: "string",
                width: "fourth",
                key: "contemporaryRecordRequired",
                onChangeCallback: setValues,
                editable: true,
                itemLimit: 1,
                validationError: validationErrors.linkCompEvent ? true : false,
                validationErrorMessage: validationErrors.linkCompEvent,
              },
            ]}
          />
        ) : null}
        {compensationEvent === 2 ? (
          <div className="compensation-event-link">
            <AppFieldBlock
              entityId={entityId}
              editing={isEditingRecovery}
              fields={[
                {
                  label: t(
                    "voc.siteEventDetails.linkToCompensationEvent"
                  ).toLocaleUpperCase(),
                  value: linkCompEvent,
                  type: "compevent",
                  width: "fourth",
                  key: "linkCompEvent",
                  onChangeCallback: setValues,
                  editable: true,
                  itemLimit: 1,
                  validationError: validationErrors.linkCompEvent
                    ? true
                    : false,
                  validationErrorMessage: validationErrors.linkCompEvent,
                },
              ]}
            />
          </div>
        ) : null}
      </AppCard>
      <TaskPanel />
    </div>
  );
};

export default InfoTabLayout;
