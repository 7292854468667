import { NxpButton, NxpPanel, NxpSpin } from "@nexploretechnology/nxp-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useAppContext from "../../../../app/hooks/useAppContext";
import { VocEvent } from "../../../../app/types";
import ReferenceFileListing from "./ReferenceFileListing";
import useReferenceFile from "./hooks/useReferenceFile";
import { editReferenceFiles } from "./reference-file-services";

const useStyles = createUseStyles({
  referenceFileList: {
    "& .ant-collapse": {
      margin: "20px",
      border: "1px solid #f0f0f0",
      borderRadius: "2px",
    },
  },
  editReferenceFileListButton: {
    "& .ant-btn": {
      margin: "0 10px",
    },
  },
});

interface ReferenceFileProps {
  event: VocEvent;
}

const ReferenceFile: React.FC<ReferenceFileProps> = ({ event }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();

  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState<{
    [id: string]: { description?: string };
  }>({});

  const {
    referenceFiles,
    setReferenceFiles,
    setLoading,
    loading,
    fetchReferenceFiles,
  } = useReferenceFile();

  const handleEditToggle = () => setEditing((prev) => !prev);

  const handleAddReferenceFile = () => {
    const newFile = {
      id: Number(new Date()),
      description: "",
      documentDate: new Date(),
      file: [],
      link: "",
      comment: "",
    };
    setReferenceFiles((prevFiles) => [...prevFiles, newFile]);
    setEditing(true);
  };

  const validateAllFields = () => {
    const newErrors: { [id: string]: { description?: string } } = {};
    referenceFiles.forEach((file) => {
      if (!file.description || file.description.trim() === "") {
        newErrors[file.id] = {
          description: t("voc.eventPage.summary.refFile.descriptionRequired"),
        };
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = async () => {
    const isValid = validateAllFields();
    if (!isValid) {
      return;
    }
    try {
      setLoading(true);
      await editReferenceFiles(event.id, referenceFiles, serviceConfig);
      await fetchReferenceFiles();
      setEditing(false);
    } catch (error) {
      errorHandler(error, "save reference files");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    fetchReferenceFiles();
    setErrors({});
  };

  return (
    <div className={classes.referenceFileList}>
      <NxpPanel
        titleContent={t("voc.eventPage.summary.refFileUploaded")}
        disableCollapse
        extra={
          editing ? (
            <>
              <NxpButton type="text" onClick={handleSaveChanges}>
                {t("voc.common.save")}
              </NxpButton>
              <NxpButton type="text" onClick={handleCancel}>
                {t("voc.common.cancel")}
              </NxpButton>
              <NxpButton type="text" onClick={handleAddReferenceFile}>
                {t("voc.common.add")}
              </NxpButton>
            </>
          ) : (
            <div className={classes.editReferenceFileListButton}>
              <NxpButton type="text" onClick={handleEditToggle}>
                {t("voc.common.edit")}
              </NxpButton>
            </div>
          )
        }
      >
        <NxpSpin spinning={loading}>
          {referenceFiles && (
            <ReferenceFileListing
              editing={editing}
              referenceFiles={referenceFiles}
              onSetReferenceFiles={setReferenceFiles}
              errors={errors}
              setErrors={setErrors}
            />
          )}
        </NxpSpin>
      </NxpPanel>
    </div>
  );
};

export default ReferenceFile;
