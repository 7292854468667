import { NxpHolder } from "@nexploretechnology/nxp-ui";
import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { VocGetChat } from "../chat.type";
import ChatInputContainer from "./ChatInputContainer";
import ChatMessage from "./ChatMessage";

const useStyles = createUseStyles(() => ({
  holder: {
    width: "95%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
  },
  messages: {
    flex: 1,
    overflowY: "auto",
  },
}));

interface ChatMessageBoxProps {
  chat: VocGetChat;
  onSetChat: React.Dispatch<React.SetStateAction<VocGetChat>>;
  activeEvent: string;
}

const ChatMessageBox: React.FC<ChatMessageBoxProps> = ({
  chat,
  onSetChat,
  activeEvent,
}) => {
  const { siteEventId } = useParams<{
    eventId: string;
    siteEventId: string;
  }>();
  const classes = useStyles();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat, activeEvent]);

  const getActiveEventMessages = () => {
    if (activeEvent === chat?.compensationEvent?.compensationEventId) {
      return chat?.compensationEvent?.chats;
    } else if (activeEvent === chat?.siteEvent?.siteEventId && siteEventId) {
      return chat?.siteEvent.chats || [];
    } else {
      const siteEvent = chat?.siteEvents?.find(
        (event) => event?.siteEventId === activeEvent
      );
      return siteEvent?.chats || [];
    }
  };

  const activeMessages = getActiveEventMessages();

  return (
    <NxpHolder className={classes.holder}>
      <div className={classes.messages}>
        <ChatMessage messages={activeMessages} />
        <div ref={messagesEndRef} />
      </div>
      <ChatInputContainer
        onSetChat={onSetChat}
        activeEvent={activeEvent}
        chat={chat}
      />
    </NxpHolder>
  );
};

export default ChatMessageBox;
