import { LayoutOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { ChatMenuItem, VocGetChat } from "../chat.type";

interface ChatMenuProps {
  chat: VocGetChat;
  onSetActiveEvent: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = createUseStyles(() => ({
  menu: {
    width: "100%",
    minHeight: "20%",
    overflowY: "auto",
  },
}));

const ChatMenu: React.FC<ChatMenuProps> = ({ chat, onSetActiveEvent }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { eventId, siteEventId } = useParams<{
    eventId: string;
    siteEventId: string;
  }>();

  const menuItems: ChatMenuItem[] = [
    {
      key: `${chat?.compensationEvent?.compensationEventId}`,
      label: `${chat?.compensationEvent?.compensationEventSerial}-${chat?.compensationEvent?.eventTitle}`,
      icon: <LayoutOutlined />,
      disabled: false,
    },
    {
      key: `relatedEvents`,
      label: t("voc.chat.relatedEvents"),
      icon: <LayoutOutlined />,
      children: chat?.siteEvents?.map((siteEvent) => ({
        key: siteEvent?.siteEventId,
        label: `${siteEvent?.siteEventSerial}-${siteEvent?.eventTitle}`,
        icon: <LayoutOutlined />,
      })),
    },
  ];

  const siteMenuItems: ChatMenuItem[] = [
    {
      key: `${chat?.siteEvent?.siteEventId}`,
      label: `${chat?.siteEvent?.siteEventSerial}-${chat?.siteEvent?.eventTitle}`,
      icon: <LayoutOutlined />,
      disabled: false,
    },
    {
      key: `relatedEvents`,
      label: t("voc.chat.relatedEvents"),
      icon: <LayoutOutlined />,
      children: [
        chat.compensationEvent && {
          key: `${chat.compensationEvent.compensationEventId}`,
          label: `${chat.compensationEvent.compensationEventSerial}-${chat.compensationEvent.eventTitle}`,
          icon: <LayoutOutlined />,
          disabled: false,
        },
      ],
    },
  ];

  const handleClick = (e: { key: string }) => {
    onSetActiveEvent(e.key);
  };

  return (
    <Menu
      mode="inline"
      className={classes.menu}
      items={siteEventId ? siteMenuItems : menuItems}
      onClick={handleClick}
      defaultOpenKeys={["relatedEvents"]}
      defaultSelectedKeys={siteEventId ? [siteEventId] : [eventId]}
    />
  );
};

export default ChatMenu;
