import { NxpSpin } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ChatMenu from "./components/ChatMenu";
import ChatMessageBox from "./components/ChatMessageBox";
import useChat from "./hooks/useChat";

interface ChatsTabContainerProps {}

const useStyles = createUseStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    height: "70vh",
    alignItems: "stretch",
  },
  menuWrapper: {
    width: "20%",
    minWidth: "150px",
    borderRight: "1px solid #f0f0f0",
    boxSizing: "border-box",
    padding: "16px",
  },
  messageBoxWrapper: {
    width: "80%",
    boxSizing: "border-box",
    padding: "16px",
    paddingTop: "0px",
  },
}));

const ChatsTabPage: React.FC<ChatsTabContainerProps> = (props) => {
  const classes = useStyles();
  const [activeEvent, setActiveEvent] = useState<string>();
  const { chat, setChat } = useChat(setActiveEvent);

  return (
    <NxpSpin spinning={!chat}>
      <div className={classes.container}>
        <div className={classes.menuWrapper}>
          {chat && <ChatMenu chat={chat} onSetActiveEvent={setActiveEvent} />}
        </div>
        <div className={classes.messageBoxWrapper}>
          {chat && (
            <ChatMessageBox
              chat={chat}
              onSetChat={setChat}
              activeEvent={activeEvent}
            />
          )}
        </div>
      </div>
    </NxpSpin>
  );
};

export default ChatsTabPage;
