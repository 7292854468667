import { NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import NotifyModalContainer from "./NotifyModalContainer";

interface NotifyButtonProps {}

const useStyles = createUseStyles({
  notificationButton: {
    position: "absolute",
    right: 0,
    marginTop: -85,
  },
});

const NotifyButton: React.FC<NotifyButtonProps> = (props) => {
  const classes = useStyles();

  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const handleModalClose = () => setShowNotifyModal(false);
  const { t } = useTranslation();
  return (
    <>
      <NxpButton
        className={classes.notificationButton}
        onClick={() => setShowNotifyModal(true)}
      >
        {t("voc.eventPage.summary.notifications")}
      </NxpButton>
      <NotifyModalContainer
        showNotifyModal={showNotifyModal}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default NotifyButton;
