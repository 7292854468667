import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  NxpButton,
  NxpHeader,
  NxpSpin,
  NxpTabs,
} from "@nexploretechnology/nxp-ui";
import { isNull } from "lodash";
import useAppContext from "../../../app/hooks/useAppContext";
import {
  VocCustomUiLayoutItemType,
  VocEvent,
  VocEventType,
  VocScreenNameEnums,
} from "../../../app/types";
import {
  getCompensationEventLogs,
  undoCompensationEventLogs,
} from "../../compensation-event-services";
import ChatsTabPage from "../../components/chats-tab/ChatTabPage";
import ClaimsTabContainer from "../../components/claims-tab/ClaimsTabContainer";
import LogsTabContainer from "../../components/logs-tab/LogsTabContainer";
import NotificationsTabPage from "../../components/notifications-tab/NotificationsTabPage";
import PlanningPage from "../../components/planning-tab/PlanningPage";
import RecordsTab from "../../components/records-tab";
import SummaryTab from "../../components/summary-tab/SummaryTab";
import SupportingTab from "../../components/supporting-tab/SupportingTab";
import ValuesTab from "../../components/value-tab-beta/ValuesTab";
import VODetailsTabContainer from "../../components/vo-details-tab/VODetailsTabContainer";

interface EventDetailsLayoutProps {
  entityId: string;
  eventId: string;
  backHref?: string;
  event?: VocEvent;
  onEventRefresh: () => void;
  nextEvent: string;
  prevEvent: string;
  preAndNextEventHandler: (eventId: string) => Promise<void>;
}

const EventDetailsLayout: React.FC<EventDetailsLayoutProps> = ({
  entityId,
  eventId,
  backHref,
  event,
  onEventRefresh,
  preAndNextEventHandler,
  nextEvent,
  prevEvent,
}) => {
  const { getCustomizedScreen, customizedScreens: screenData } =
    useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkVoTabDisplay = () => {
    const voScreen = getCustomizedScreen(
      VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
    );
    if (!voScreen) {
      return false;
    }

    return voScreen.layout.some(
      (item) =>
        item.type === VocCustomUiLayoutItemType.LayoutGroup && item.display
    );
  };

  const staticTabs = [
    {
      key: "summary",
      label: t("voc.eventPage.tabSummary"),
      children: event && (
        <SummaryTab
          onEventRefresh={onEventRefresh}
          event={event}
          customUi={getCustomizedScreen(
            VocScreenNameEnums.COMPENSATION_EVENT_SUMMARY_SCREEN
          )}
        />
      ),
    },
    {
      key: "notifications",
      label: t("voc.eventPage.tabNotifications"),
      children: <NotificationsTabPage eventId={eventId} />,
    },
    {
      key: "records",
      label: t("voc.eventPage.tabRecords"),
      children: event && (
        <RecordsTab
          onEventRefresh={onEventRefresh}
          event={event}
          entityId={entityId}
          eventId={eventId}
          contemporaryRecordRequired={event?.contemporaryRecordRequired}
        />
      ),
    },
  ];

  const voDetailsTab =
    (event?.eventType === VocEventType.VO ||
      event?.eventType === VocEventType.VO_And_Claim) &&
    screenData &&
    checkVoTabDisplay()
      ? {
          key: "voDetails",
          label: t("voc.eventPage.tabVODetails"),
          children: (
            <VODetailsTabContainer
              eventId={eventId}
              onEventRefresh={onEventRefresh}
              customUi={getCustomizedScreen(
                VocScreenNameEnums.COMPENSATION_EVENT_VO_DETAIL_SCREEN
              )}
            />
          ),
        }
      : null;

  const claimsTab =
    event?.eventType === VocEventType.Claim ||
    event?.eventType === VocEventType.VO_And_Claim
      ? {
          key: "claims",
          label: t("voc.eventPage.tabClaims"),
          children: (
            <ClaimsTabContainer event={event} onEventRefresh={onEventRefresh} />
          ),
        }
      : null;

  const remainingTabs = [
    {
      key: "values",
      label: t("voc.eventPage.tabValues"),
      children: <ValuesTab />,
    },
    {
      key: "planning",
      label: t("voc.eventPage.tabPlanning"),
      children: <PlanningPage />,
    },
    {
      key: "supporting",
      label: t("voc.eventPage.tabSupporting"),
      children: <SupportingTab eventDetail={event} />,
    },
    {
      key: "chats",
      label: t("voc.eventPage.tabChats"),
      children: <ChatsTabPage />,
    },
    {
      key: "logs",
      label: t("voc.eventPage.tabLogs"),
      children: (
        <LogsTabContainer
          permissionCode="compensation_event"
          id={eventId}
          onFetch={getCompensationEventLogs}
          onUndo={undoCompensationEventLogs}
          onEventRefresh={onEventRefresh}
        />
      ),
    },
  ];

  let items = [...staticTabs];

  if (voDetailsTab) {
    items.splice(3, 0, voDetailsTab);
  }

  if (claimsTab) {
    const valuesTabIndex = items.findIndex((item) => item.key === "values");
    if (valuesTabIndex !== -1) {
      items.splice(valuesTabIndex, 0, claimsTab);
    } else {
      items.push(claimsTab);
    }
  }

  items = [...items, ...remainingTabs];

  const filteredItems = items.filter(
    (item) => item.children !== null && item.children !== undefined
  );

  const [activeTab, setActiveTab] = React.useState<string>("summary");

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <NxpSpin spinning={!event}>
      <div className="voc-event-page">
        <NxpHeader
          titleContent={event?.eventTitle ?? t("voc.common.ce")}
          subheadingContent={event?.serial}
          onBackClick={() => navigate(backHref)}
          actionContent={
            <>
              {prevEvent && !isNull(prevEvent) && (
                <NxpButton
                  type="text"
                  onClick={() =>
                    preAndNextEventHandler(prevEvent).finally(() =>
                      navigate(`./../../compensation-events/${prevEvent}`)
                    )
                  }
                >
                  <span className="arrow">◀</span>{" "}
                  {t("voc.siteEventDetails.prevSiteEvent")}
                </NxpButton>
              )}
              {nextEvent && !isNull(nextEvent) && (
                <NxpButton
                  type="text"
                  onClick={() =>
                    preAndNextEventHandler(nextEvent).finally(() =>
                      navigate(`./../../compensation-events/${nextEvent}`)
                    )
                  }
                >
                  {t("voc.siteEventDetails.nextSiteEvent")}{" "}
                  <span className="arrow">▶</span>
                </NxpButton>
              )}
            </>
          }
        />
        <NxpTabs
          activeKey={activeTab}
          onChange={handleTabChange}
          items={filteredItems}
        />
      </div>
    </NxpSpin>
  );
};

export default EventDetailsLayout;
