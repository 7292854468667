import { NxpSelectUserOption, sorterBuilder } from "@nexploretechnology/nxp-ui";
import _ from "lodash";
import { useMemo } from "react";
import { User } from "../../access-control/access-right-services";
import { EntityUser } from "../services/app";
import { getUserDisplayName } from "../utils/helper/get-user-display-name";
import useAppContext from "./useAppContext";

export const useSelectUserOptions = () => {
  const { entityUsers } = useAppContext();

  const getUserOptionsForTag = (
    selectedUserOptions: EntityUser[]
  ): (NxpSelectUserOption & { user?: EntityUser; tag: string })[] => {
    const deduplicatedSelectedUsers = _.uniqBy(selectedUserOptions, "id");

    return deduplicatedSelectedUsers
      .filter((user) => user.isSuspended)
      .map((user) => {
        return {
          id: user.id,
          name: getUserDisplayName(user, true, true),
          tag: getUserDisplayName(user, false, true),
        };
      })
      .concat(
        entityUsers.map((user) => ({
          id: user.id,
          name: getUserDisplayName(user, true, true),
          tag: getUserDisplayName(user),
        }))
      );
  };

  const getUserOptionsForSelect = (
    //no sure why user id type can be string or number
    selectedUserOptions: User[]
  ): {
    value: string;
    label: string;
  }[] => {
    return selectedUserOptions
      .filter((user) => user.isSuspended)
      .map((user) => {
        return {
          value: String(user.id),
          label: getUserDisplayName(user, true, true),
          suspended: user.isSuspended,
        };
      })
      .concat(
        entityUsers.map((user) => ({
          value: user.id,
          label: getUserDisplayName(user, true, true),
          suspended: user.isSuspended,
        }))
      );
  };

  const userOptionsForMentions = useMemo(
    () =>
      entityUsers
        .sort(sorterBuilder<EntityUser>("displayName"))
        .map((user) => ({
          id: user.id,
          display: getUserDisplayName(user, false),
        })),
    [entityUsers]
  );

  return {
    getUserOptionsForTag,
    getUserOptionsForSelect,
    userOptionsForMentions,
  };
};
