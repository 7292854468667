import { NxpButton, NxpPanel, NxpSpin } from "@nexploretechnology/nxp-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../app/hooks/useAppContext";
import { getPlanning, updatePlanning } from "../planning-services";
import { VocPlanningGeneralInformation } from "../planning-type";
import GeneralInformationTable from "./GeneralInformationTable";

const useStyles = createUseStyles({
  editButton: {
    "& .ant-btn": {
      margin: "0 10px",
    },
  },
  generalInformationPanel: {
    margin: 20,
  },
});

const GeneralInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const { serviceConfig, errorHandler } = useAppContext();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planningGeneralInformation, setPlanningGeneralInformation] =
    useState<VocPlanningGeneralInformation>();

  const fetchPlanningGeneralInformation = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPlanning(eventId, serviceConfig);
      setPlanningGeneralInformation({
        planningOwner: response.planningOwner,
        impactedActivities: response.impactedActivities,
        planningReference: response.planningReference,
        constructionStatus: response.constructionStatus,
      });
    } catch (err) {
      errorHandler(err, "fetch planning general information");
    } finally {
      setLoading(false);
    }
  }, [eventId, serviceConfig, errorHandler]);

  useEffect(() => {
    fetchPlanningGeneralInformation();
  }, [fetchPlanningGeneralInformation]);

  const handleEditToggle = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updatePlanning(
        eventId,
        {
          planningReference: planningGeneralInformation.planningReference,
          planningOwnerId: planningGeneralInformation.planningOwner
            ? planningGeneralInformation.planningOwner.id
            : null,
          impactedActivities: planningGeneralInformation.impactedActivities,
          constructionStatus: planningGeneralInformation.constructionStatus,
        },
        serviceConfig
      );
    } catch (err) {
      errorHandler(err, "update planning general information");
    } finally {
      setLoading(false);
      setEditing(false);
      fetchPlanningGeneralInformation();
    }
  };

  const handleCancel = () => {
    setEditing(false);
    fetchPlanningGeneralInformation();
  };

  return (
    <NxpSpin spinning={loading}>
      <NxpPanel
        className={classes.generalInformationPanel}
        titleContent={t("voc.eventPage.generalInformation")}
        disableCollapse={true}
        extra={
          <div className={classes.editButton}>
            {editing ? (
              <>
                <NxpButton type="text" onClick={handleSave} loading={loading}>
                  {t("voc.common.save")}
                </NxpButton>
                <NxpButton type="text" onClick={handleCancel}>
                  {t("voc.common.cancel")}
                </NxpButton>
              </>
            ) : (
              <NxpButton type="text" onClick={handleEditToggle}>
                {t("voc.common.edit")}
              </NxpButton>
            )}
          </div>
        }
      >
        <GeneralInformationTable
          planningGeneralInformation={planningGeneralInformation}
          editing={editing}
          onSetPlanningGeneralInformation={setPlanningGeneralInformation}
        />
      </NxpPanel>
    </NxpSpin>
  );
};

export default GeneralInformation;
