import { NxpButtonDeleteConfirm } from "@nexploretechnology/nxp-ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppContext from "../../../../../app/hooks/useAppContext";
import { updateCompEventTasks } from "../task-services";
import { TaskFormStateItem, VocTask } from "../task-type";

interface DeleteTaskButtonProps {
  id: string | number; // number for new task, string for existing task
  record: VocTask & TaskFormStateItem;
  onSetFormState: (value: React.SetStateAction<TaskFormStateItem[]>) => void;
}

const DeleteTaskButton: React.FC<DeleteTaskButtonProps> = ({
  record,
  id,
  onSetFormState,
}) => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { serviceConfig, errorHandler } = useAppContext();

  const handleDelete = useCallback(
    async (id: string | number) => {
      if (typeof id === "number") {
        onSetFormState((prev) => prev.filter((task) => task.id !== record.id));
        return;
      }
      try {
        await updateCompEventTasks(
          eventId,
          {
            tasks: [
              {
                taskDetails: record.taskDetails,
                assignedToId: record.assignedTo.id,
                deadline: record.deadline,
                taskStatus: record.taskStatus,
                id: record.id,
                deleted: true,
              },
            ],
          },
          serviceConfig
        );
        onSetFormState((prev) => prev.filter((task) => task.id !== record.id));
      } catch (error) {
        errorHandler(error, "delete task");
      }
    },
    [
      errorHandler,
      eventId,
      onSetFormState,
      record.assignedTo?.id,
      record.deadline,
      record.id,
      record.taskDetails,
      record.taskStatus,
      serviceConfig,
    ]
  );

  return (
    <NxpButtonDeleteConfirm onConfirm={() => handleDelete(id)}>
      {t("voc.feedback.delete")}
    </NxpButtonDeleteConfirm>
  );
};

export default DeleteTaskButton;
