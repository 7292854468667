// @ts-ignore
import { getTimezoneOffset } from "date-fns-tz";
import { DateTime } from "luxon";

import { ColumnDataType } from "../components/app-data-table";
import { FilterOption, FilterType } from "../components/app-filter";
import {
  Dashboard,
  ResponseWithPaginationLegacy,
  UserInfo,
  VarianceReport,
  VocCustomUi,
  VocCustomUiFieldSchema,
  VocCustomUiLayoutItem,
  VocCustomUiObjectType,
  VocEvent,
  VocEventCreation,
  VocEventListItem,
  VocEventShort,
  VocEventStatusRecord,
  VocEventSummaryNotification,
  VocFeedback,
  VocFeedbackCreation,
  VocFeedbackListItem,
  VocGetDictionaryResponse,
  VocGetLibrariesResponse,
  VocGetLibraryUsage,
  VocGetLogs,
  VocGetRecentMessagesResponse,
  VocGetSupportDocumentResponse,
  VocGetValuesResponse,
  VocLibraryObject,
  VocProjectLibraryType,
  VocRecordStatus,
  VocSiteEvent,
  VocSiteEventListItem,
  VocSiteEventPatchRequest,
  VocSiteEventPostRequest,
  VocSiteEventQueryItem,
  VocSupportDocument,
  VocUpdateLibraryRequest,
  VocUpdateLibrarySortRequest,
  VocUpdateSubrecordsRequest,
  VocUpdateSubrecordsResponse,
  VocUpdateSupportDocument,
  VocUpdateValuesRequest,
  VocValueRegister,
  VocVO,
} from "../types";
import {
  VocGetNotificationsResponse,
  VocUpdateNotificationsRequest,
} from "../types/notifications";
import {
  VocAllSubContractorsData,
  VocAllSubContractorsDataPackage,
  VocDeleteSubcontractor,
  VocLinkedSubcontractorDataPackage,
  VocLinkedSubcontractorsListDataPackage,
  VocNotifySubcontractors,
  VocSubcontractorsToNotifyList,
  VocUpdatedApplicableTypeOfSubcontractor,
} from "../types/subcontractor";
import { apiRequest, ServiceConfig } from "../utils/backend/api-request";
import {
  FORMAT_SHORT_DATE_LUXON,
  REGISTER_LAZY_LOAD_SIZE,
} from "../utils/const";
import { buildQueryString } from "../utils/string";
import apiRequestLegacy from "./apiRequestLegacy";
import { convertToJsonBody } from "./convert-to-json-body";

export interface RemarkAttachment {
  id: number;
  createdAt: Date;
  remarkId: number;
  assetId: string;
  fileName: string;
  mimeType: string;
  url?: string;
}

export interface Config {
  authPrefix: string;
  entityPrefix: string;
  formPrefix: string;
  workflowPrefix: string;
  entryPrefix: string;
  configPrefix: string;
  accessPrefix: string;
  filePrefix: string;
  siteDiaryPrefix: string;
  qualityAlertPrefix: string;
  notificationPrefix: string;
  qualityStandardPrefix: string;
  inspectionPrefix: string;
  ierPrefix: string;
  vocPrefix: string;
}

export interface PageRequest {
  perPage: number;
  page?: string;
  sort?: string;
  order?: string;
  search?: string;
}

export function getConfig(): Config {
  // return configSchema.validateSync((window as any).cpcs.config);
  return {
    authPrefix: "tmpPrefix",
    entityPrefix: "tmpPrefix",
    formPrefix: "tmpPrefix",
    workflowPrefix: "tmpPrefix",
    entryPrefix: "tmpPrefix",
    configPrefix: "tmpPrefix",
    accessPrefix: "tmpPrefix",
    filePrefix: "tmpPrefix",
    siteDiaryPrefix: "tmpPrefix",
    qualityAlertPrefix: "tmpPrefix",
    notificationPrefix: "tmpPrefix",
    qualityStandardPrefix: "tmpPrefix",
    inspectionPrefix: "tmpPrefix",
    ierPrefix: "tmpPrefix",
    vocPrefix: "compensation-events",
  };
}

export class VocService {
  private endpoint: string;
  private timezoneHourAdjustment: number;

  public constructor(
    private entityId: string,
    private entityTimezone: string,
    private serviceConfig: ServiceConfig
  ) {
    this.endpoint = getConfig().vocPrefix;
    const entityTimezoneOffset =
      getTimezoneOffset(this.entityTimezone) / 3600000;
    const browserTimezoneOffset = (-1 * new Date().getTimezoneOffset()) / 60; // javascript getTimezoneOffset
    this.timezoneHourAdjustment = entityTimezoneOffset - browserTimezoneOffset;
  }

  private getApiRoute(path: string): string {
    return `${this.serviceConfig.apiBaseUrl}/${this.endpoint}/entities/${this.entityId}${path}`;
  }

  // Start general event calls

  public getEvent(eventId: number): Promise<VocEvent> {
    // t=${new Date().getTime() to avoid json response caching after back navigation by user
    return apiRequestLegacy<VocEvent>(
      this.getApiRoute(`/events/${eventId}?t=${new Date().getTime()}`),
      this.timezoneHourAdjustment
    );
  }

  public createEvent(event: VocEventCreation): Promise<VocEvent> {
    return apiRequestLegacy<VocEvent>(
      this.getApiRoute(`/events`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(event, this.timezoneHourAdjustment),
      }
    );
  }
  // Start general event calls

  public getFeedback(
    pageRequest: Partial<PageRequest>,
    filterOptions?: FilterOption[]
  ): Promise<VocFeedbackListItem[]> {
    const filters = !filterOptions
      ? []
      : this.prepareFilterOptions(filterOptions);

    const query = {
      sortColumn: pageRequest.sort,
      sortOrder: pageRequest.order,
      filters: filters.length > 0 ? filters : undefined,
    };
    const qs = buildQueryString({
      query,
    });
    return apiRequestLegacy<VocFeedbackListItem[]>(
      `${this.endpoint}/feedbacks${qs}`,
      this.timezoneHourAdjustment
    );
  }

  public createFeedback(
    event: Partial<VocFeedbackCreation>
  ): Promise<VocFeedback> {
    return apiRequestLegacy<VocFeedback>(
      `${this.endpoint}/feedbacks/entities/${"window.cpcs.entity"}`,
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(event, this.timezoneHourAdjustment),
      }
    );
  }

  public async getAllUsers(): Promise<UserInfo[]> {
    return apiRequestLegacy<{ users: UserInfo[] }>(
      `${getConfig().entityPrefix}/entities/${this.entityId}/users`,
      this.timezoneHourAdjustment
    ).then((data) => data.users);
  }

  private prepareFilterOptions = (filterOptions: FilterOption[]) => {
    if (!filterOptions) {
      return [];
    }

    const greaterThanFilterOptions: FilterOption[] = [];
    const lessThanFilterOptions: FilterOption[] = [];
    const betweenFilterOptions: { from: FilterOption; to: FilterOption }[] = [];

    filterOptions.forEach((filterOpt) => {
      switch (filterOpt.filterType) {
        case FilterType.GreaterThanOrEqual:
          {
            const greaterOpt = greaterThanFilterOptions.find(
              (greaterOpt) => greaterOpt.fieldName === filterOpt.fieldName
            );
            if (!greaterOpt) {
              greaterThanFilterOptions.push(filterOpt);
            } else {
              switch (greaterOpt.columnDataType) {
                case ColumnDataType.Date:
                  {
                    const greaterOptDateValue = new Date(
                      greaterOpt.filterValue
                    );
                    const filterOptDateValue = new Date(filterOpt.filterValue);
                    if (filterOptDateValue > greaterOptDateValue) {
                      greaterOpt.filterValue = filterOpt.filterValue;
                    }
                  }
                  break;
                case ColumnDataType.Money:
                case ColumnDataType.Number:
                case ColumnDataType.Percent:
                  {
                    const greaterOptNumValue = Number(greaterOpt.filterValue);
                    const filterOptNumValue = Number(filterOpt.filterValue);
                    if (filterOptNumValue > greaterOptNumValue) {
                      greaterOpt.filterValue = filterOpt.filterValue;
                    }
                  }
                  break;
              }
            }
          }
          break;
        case FilterType.LessThanOrEqual:
          {
            const lessOpt = lessThanFilterOptions.find(
              (lessOpt) => lessOpt.fieldName === filterOpt.fieldName
            );
            if (!lessOpt) {
              lessThanFilterOptions.push(filterOpt);
            } else {
              switch (lessOpt.columnDataType) {
                case ColumnDataType.Date:
                  {
                    const lessOptDateValue = new Date(lessOpt.filterValue);
                    const filterOptDateValue = new Date(filterOpt.filterValue);
                    if (filterOptDateValue < lessOptDateValue) {
                      lessOpt.filterValue = filterOpt.filterValue;
                    }
                  }
                  break;
                case ColumnDataType.Money:
                case ColumnDataType.Number:
                case ColumnDataType.Percent:
                  {
                    const lessOptNumValue = Number(lessOpt.filterValue);
                    const filterOptNumValue = Number(filterOpt.filterValue);
                    if (filterOptNumValue < lessOptNumValue) {
                      lessOpt.filterValue = filterOpt.filterValue;
                    }
                  }
                  break;
              }
            }
          }
          break;
      }
    });

    greaterThanFilterOptions.forEach((greaterOpt) => {
      const lessOpt = lessThanFilterOptions.find((lessOpt) => {
        if (lessOpt.fieldName === greaterOpt.fieldName) {
          switch (lessOpt.columnDataType) {
            case ColumnDataType.Date:
              {
                const lessOptDateValue = new Date(lessOpt.filterValue);
                const greaterOptDateValue = new Date(greaterOpt.filterValue);
                if (greaterOptDateValue < lessOptDateValue) {
                  return true; // treat as between
                }
              }
              break;
            case ColumnDataType.Money:
            case ColumnDataType.Number:
            case ColumnDataType.Percent:
              {
                const lessOptNumValue = Number(lessOpt.filterValue);
                const greaterOptNumValue = Number(greaterOpt.filterValue);
                if (greaterOptNumValue < lessOptNumValue) {
                  return true; // treat as between
                }
              }
              break;
          }
        }
        return false;
      });

      if (lessOpt) {
        betweenFilterOptions.push({
          from: greaterOpt,
          to: lessOpt,
        });
      }
    });

    const returnOptions = filterOptions.filter(
      // filter out options for between comparison
      (item) =>
        !(
          (item.filterType === FilterType.GreaterThanOrEqual ||
            item.filterType === FilterType.LessThanOrEqual) &&
          betweenFilterOptions.find(
            (betweenOpt) => betweenOpt.from.fieldName === item.fieldName
          )
        )
    );

    return returnOptions
      .map((option) => {
        let value: boolean | Date | number | string | Date[] | number[];
        let valueTypeHolder: typeof value; // typeof value for getting around typescript error
        switch (option.columnDataType) {
          case ColumnDataType.Boolean:
            value =
              option.filterType === FilterType.IsEmpty
                ? null
                : option.filterValue === "true";
            break;
          case ColumnDataType.Date:
            value = DateTime.fromFormat(
              option.filterValue,
              FORMAT_SHORT_DATE_LUXON
            ).toJSDate();
            break;
          case ColumnDataType.Number:
          case ColumnDataType.Money:
          case ColumnDataType.Percent:
            value = Number(option.filterValue);
            break;
          default:
            //case ColumnDataType.String:
            value = option.filterValue;
            break;
        }

        return {
          column: option.fieldName,
          type: (option.filterType === FilterType.IsEmpty
            ? FilterType.Equal
            : option.filterType
          )
            .replace(/ /g, "")
            .replace("GreaterThanOrEqual", "GreaterEqual")
            .replace("LessThanOrEqual", "LessEqual"),
          value: value as typeof valueTypeHolder,
        };
      })
      .concat(
        betweenFilterOptions.map((betweenFilter) => {
          let value: Date[] | number[];
          switch (betweenFilter.from.columnDataType) {
            case ColumnDataType.Date:
              value = [
                DateTime.fromFormat(
                  betweenFilter.from.filterValue,
                  FORMAT_SHORT_DATE_LUXON
                ).toJSDate(),
                DateTime.fromFormat(
                  betweenFilter.to.filterValue,
                  FORMAT_SHORT_DATE_LUXON
                ).toJSDate(),
              ];
              break;
            case ColumnDataType.Number:
            case ColumnDataType.Money:
            case ColumnDataType.Percent:
              value = [
                Number(betweenFilter.from.filterValue),
                Number(betweenFilter.to.filterValue),
              ];
              break;
            default:
              value = [];
              break;
          }

          return {
            column: betweenFilter.from.fieldName,
            type: FilterType.Between,
            value,
          };
        })
      );
  };

  public async getEventList(
    pageRequest: PageRequest,
    // extraParams: TableRefreshExtraParams<keyof DemoStatusCount>,
    filterOptions?: FilterOption[]
  ): Promise<{
    listItems: VocEventListItem[];
    totalRecordCount: number;
    totalValues?: VocEventListItem;
  }> {
    const filters = !filterOptions
      ? []
      : this.prepareFilterOptions(filterOptions);

    if (pageRequest.search) {
      // handle table search box value
      filters.push({
        column: "serial",
        type: FilterType.Contains,
        value: pageRequest.search,
      });
    }

    const withTotal = pageRequest.perPage === 0 && pageRequest.page === "1";

    // pageRequest.perPage === 0 for lazy loading
    const perPage =
      pageRequest.perPage === 0 ? REGISTER_LAZY_LOAD_SIZE : pageRequest.perPage;

    const qs = buildQueryString({
      total: withTotal ? "true" : undefined,
      query: {
        limit: perPage,
        offset: parseInt(pageRequest.page)
          ? (parseInt(pageRequest.page) - 1) * perPage
          : 0,
        sortColumn: pageRequest.sort,
        sortOrder: pageRequest.order,
        filters: filters.length > 0 ? filters : undefined,
      },
    });

    const data = await apiRequestLegacy<
      // ResponseWithPagination<VocEventListItem[]>
      VocEventListItem[]
    >(this.getApiRoute(`/events${qs}`), this.timezoneHourAdjustment);
    // return {
    //   listItems: data.results,
    //   totalRecordCount: data.pagination.countAll,
    //   totalValues: data.totals,
    // };
    return {
      listItems: data,
      totalRecordCount: data.length,
      totalValues: undefined,
    };
  }

  public getEventListShort(): Promise<VocEventShort[]> {
    return apiRequestLegacy<VocEventShort[]>(
      this.getApiRoute(`/search/compensation_events`),
      this.timezoneHourAdjustment
    );
  }

  // End general event calls

  // Start SITE event calls
  public getSiteEvent(siteEventId: number): Promise<VocSiteEvent> {
    // t=${new Date().getTime() to avoid json response caching after back navigation by user
    return apiRequestLegacy<VocSiteEvent>(
      this.getApiRoute(`/site_events/${siteEventId}?t=${new Date().getTime()}`),
      this.timezoneHourAdjustment
    );
  }

  public updateSiteEvent(
    siteEventId: number,
    siteEvent: VocSiteEventPatchRequest
  ): Promise<VocSiteEvent> {
    return apiRequestLegacy<VocSiteEvent>(
      this.getApiRoute(`/site_events/${siteEventId}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(siteEvent, this.timezoneHourAdjustment),
      }
    );
  }

  public createSiteEvent(
    siteEvent: VocSiteEventPostRequest
  ): Promise<VocSiteEvent> {
    return apiRequestLegacy<VocSiteEvent>(
      this.getApiRoute(`/site_events`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(siteEvent, this.timezoneHourAdjustment),
      }
    );
  }

  public async getSiteEventsQuery(filterText: string) {
    // api build for picker option list
    return await apiRequestLegacy<VocSiteEventQueryItem[]>(
      this.getApiRoute(`/search/site_events?query=${filterText}`),
      this.timezoneHourAdjustment
    );
  }

  public async getSiteEventList(
    pageRequest: PageRequest,
    // extraParams: TableRefreshExtraParams<keyof DemoStatusCount>,
    filterOptions?: FilterOption[]
  ): Promise<{ listItems: VocSiteEventListItem[]; totalRecordCount: number }> {
    const filters = this.prepareFilterOptions(filterOptions || []);

    if (pageRequest.search) {
      // handle table search box value
      filters.push({
        column: "serial",
        type: FilterType.Contains,
        value: pageRequest.search,
      });
    }

    // pageRequest.perPage === 0 for lazy loading
    const perPage =
      pageRequest.perPage === 0 ? REGISTER_LAZY_LOAD_SIZE : pageRequest.perPage;

    const qs = buildQueryString({
      query: {
        limit: perPage,
        offset: parseInt(pageRequest.page)
          ? (parseInt(pageRequest.page) - 1) * perPage
          : 0,
        sortColumn: pageRequest.sort,
        sortOrder: pageRequest.order,
        filters: filters.length > 0 ? filters : undefined,
      },
    });

    const data = await apiRequestLegacy<
      ResponseWithPaginationLegacy<VocSiteEventListItem[]>
    >(this.getApiRoute(`/site_events${qs}`), this.timezoneHourAdjustment);

    return {
      listItems: data.results,
      totalRecordCount: data.pagination.countAll,
    };
  }

  // End SITE event calls

  // Start event summary calls

  public async getVOSelectList(): Promise<
    { id: number; title: string; serial: string }[]
  > {
    return apiRequestLegacy<{
      results: { id: number; title: string; serial: string }[];
    }>(this.getApiRoute("/events/type/vo"), this.timezoneHourAdjustment).then(
      (data: any) => data.results
    );
  }

  public async getClaimSelectList(): Promise<
    { id: number; title: string; serial: string }[]
  > {
    return apiRequestLegacy<{
      results: { id: number; title: string; serial: string }[];
    }>(
      this.getApiRoute("/events/type/claim"),
      this.timezoneHourAdjustment
    ).then((data: any) => data.results);
  }

  public updateEvent(
    eventId: number,
    event: Partial<VocEvent>
  ): Promise<VocEvent> {
    const eventReshaped: { [key: string]: any } = { ...event };
    if (eventReshaped.eventType) {
      eventReshaped.eventType = event.eventType;
    }
    if (eventReshaped.statusWithClient) {
      eventReshaped.statusWithClient = event.statusWithClient;
    }
    if (eventReshaped.commercialOwner) {
      eventReshaped.commercialOwner = event.commercialOwner.id;
    }
    if (eventReshaped.recordOwner) {
      eventReshaped.recordOwner = event.recordOwner.id;
    }
    if (eventReshaped.recordSupporter) {
      eventReshaped.recordSupporter = event.recordSupporter.id;
    }
    if (eventReshaped.delayDisruptiveEffect) {
      eventReshaped.delayDisruptiveEffect = event.delayDisruptiveEffect.map(
        (item: any) => item.value
      );
    }
    if (eventReshaped.siteEvents) {
      eventReshaped.siteEvents = event.siteEvents.map((item: any) => item.id);
    }
    if (eventReshaped.eventSource) {
      eventReshaped.eventSource = event.eventSource;
    }
    if (eventReshaped.instructionOwner) {
      eventReshaped.instructionOwner = event.instructionOwner;
    }
    if (eventReshaped.locations) {
      eventReshaped.locations = event.locations.map((item: any) => item.value);
    }
    if (eventReshaped.eventSourceType) {
      eventReshaped.eventSourceType = event.eventSourceType;
    }

    // t=${new Date().getTime() to avoid json response caching after back navigation by user
    return apiRequestLegacy<VocEvent>(
      this.getApiRoute(`/events/${eventId}?t=${new Date().getTime()}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(eventReshaped, this.timezoneHourAdjustment),
      }
    );
  }

  public createEventSummaryNotification(
    eventId: number,
    summaryNotification: VocEventSummaryNotification
  ): Promise<VocEventSummaryNotification> {
    return apiRequestLegacy<VocEventSummaryNotification>(
      this.getApiRoute(`/events/${eventId}/event_notifications`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          summaryNotification,
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  // End event summary calls

  // Start event vo details calls

  public getEventVO(eventId: number): Promise<VocVO> {
    return apiRequestLegacy<VocVO>(
      this.getApiRoute(`/events/${eventId}/vo`),
      this.timezoneHourAdjustment
    );
  }

  public updateEventVO(eventId: number, vo: Partial<VocVO>): Promise<VocVO> {
    return apiRequestLegacy<VocVO>(
      this.getApiRoute(`/events/${eventId}/vo`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        headers: {
          accept: "application/json",
        },
        body: convertToJsonBody(vo, this.timezoneHourAdjustment),
      }
    );
  }

  // End event vo details calls

  public getSupportDocuments(
    eventId: number
  ): Promise<VocGetSupportDocumentResponse> {
    return apiRequestLegacy<VocGetSupportDocumentResponse>(
      this.getApiRoute(`/events/${eventId}/support_document`),
      this.timezoneHourAdjustment
    );
  }

  // End event supporting calls

  // start SITE event supporting document calls

  public createSiteEventSupportDocument(
    siteEventId: number,
    serviceConfig: ServiceConfig,
    supportDocument: Partial<VocSupportDocument>
  ): Promise<VocSupportDocument> {
    const supportDocument_: { [key: string]: any } = { ...supportDocument };
    Object.keys(supportDocument_).forEach((key) => {
      if (supportDocument_[key] === null) {
        // remove null field for other document type to meet api requirement
        supportDocument_[key] = undefined;
      }
    });
    if (supportDocument_.subcontractor) {
      supportDocument_.subcontractorId = supportDocument_.subcontractor.id;
      supportDocument_.subcontractor = undefined;
    }
    return apiRequest<VocSupportDocument>(
      `${serviceConfig.apiBaseUrl}/support-documents/entities/${serviceConfig.entityId}/site_events/${siteEventId}/support_document`,
      serviceConfig,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(supportDocument_, this.timezoneHourAdjustment),
      }
    );
  }

  public getSiteEventSupportDocuments(
    siteEventId: number,
    serviceConfig: ServiceConfig
  ): Promise<VocGetSupportDocumentResponse> {
    console.log("hello support");

    return apiRequest<VocGetSupportDocumentResponse>(
      `${serviceConfig.apiBaseUrl}/support-documents/entities/${serviceConfig.entityId}/site_events/${siteEventId}/support_document`,
      serviceConfig
    );
  }
  // end SITE event supporting document calls

  // Start event notification calls

  public getNotifications(
    eventId: number
  ): Promise<VocGetNotificationsResponse> {
    return apiRequestLegacy<VocGetNotificationsResponse>(
      this.getApiRoute(`/events/${eventId}/notifications_v2`),
      this.timezoneHourAdjustment
    );
  }

  public editNotifications(
    eventId: number,
    notificationUpdate: VocUpdateNotificationsRequest
  ): Promise<VocUpdateNotificationsRequest> {
    return apiRequestLegacy<VocUpdateNotificationsRequest>(
      this.getApiRoute(`/events/${eventId}/notifications_v2`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(
          notificationUpdate,
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public createNotificationReminder(
    eventId: number,
    reminderNotification: VocEventSummaryNotification
  ): Promise<VocEventSummaryNotification> {
    return apiRequestLegacy<VocEventSummaryNotification>(
      this.getApiRoute(`/events/${eventId}/reminder_notifications`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          reminderNotification,
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  // End event notification calls

  // Start SUBCONTRACTORS TAB service

  public getSubcontractors(): Promise<VocAllSubContractorsDataPackage> {
    return apiRequestLegacy<VocAllSubContractorsDataPackage>(
      this.getApiRoute(`/subcontractors`),
      this.timezoneHourAdjustment
    );
  }

  public deleteLinkedSubcontractor(
    subcontractorId: number,
    event_id: number
  ): Promise<VocDeleteSubcontractor> {
    return apiRequestLegacy<VocDeleteSubcontractor>(
      ///voc/entities/1/subcontractors/2
      ///entities/{entity_id}/events/{event_id}/subcontractors/{subcontractor_id}
      this.getApiRoute(`/events/${event_id}/subcontractors/${subcontractorId}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "DELETE",
      }
    );
  }

  public getLinkedSubcontractors(
    event_id: number
  ): Promise<VocLinkedSubcontractorsListDataPackage> {
    return apiRequestLegacy<VocLinkedSubcontractorsListDataPackage>(
      this.getApiRoute(`/events/${event_id}/subcontractors`),
      this.timezoneHourAdjustment
    );
  }

  public linkSubcontractor(
    entity_id: number,
    event_id: number,
    selectedSubContractorObject: VocAllSubContractorsData
  ): Promise<VocLinkedSubcontractorDataPackage> {
    return apiRequestLegacy<VocLinkedSubcontractorDataPackage>(
      this.getApiRoute(`/events/${event_id}/subcontractors`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          {
            id: selectedSubContractorObject?.id,
            // name:selectedSubContractorObject?.name,
            applicable: true,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public notifySubcontractors(
    event_id: number,
    subcontractors: VocSubcontractorsToNotifyList[]
  ): Promise<VocNotifySubcontractors> {
    return apiRequestLegacy<VocNotifySubcontractors>(
      this.getApiRoute(`/events/${event_id}/subcontractors_notifications`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          {
            subcontractors: subcontractors,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }
  public updateApplicableStatusOfSubcontractor(
    event_id: number,
    checked: boolean,
    subcontractorId: number
  ): Promise<VocUpdatedApplicableTypeOfSubcontractor> {
    return apiRequestLegacy<VocUpdatedApplicableTypeOfSubcontractor>(
      //voc/entities/1/events/400/subcontractors/3
      //voc/entities/1/events/1/subcontractors/2
      this.getApiRoute(`/events/${event_id}/subcontractors/${subcontractorId}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(
          {
            applicable: checked,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  // End SUBCONTRACTORS TAB service

  // Start COMPENSATION event records tab calls

  public getStatusRecords(eventId: number): Promise<VocEventStatusRecord[]> {
    return apiRequestLegacy<{ results: VocEventStatusRecord[] }>(
      this.getApiRoute(`/events/${eventId}/records`),
      this.timezoneHourAdjustment
    ).then((data: any) => data.results);
  }

  public createStatusRecord(
    start: Date,
    status: VocRecordStatus,
    remarks: string,
    eventId: number
  ): Promise<VocEventStatusRecord[]> {
    return apiRequestLegacy<VocEventStatusRecord[]>(
      this.getApiRoute(`/events/${eventId}/records`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          {
            start,
            status: { value: status },
            remarks,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public deleteStatusRecords(
    eventId: number,
    start: Date,
    finish?: Date
  ): Promise<VocEventStatusRecord[]> {
    const qs = buildQueryString({
      query: {
        start,
        finish,
      },
    });

    return apiRequestLegacy<VocEventStatusRecord[]>(
      this.getApiRoute(`/events/${eventId}/records${qs}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "DELETE",
      }
    );
  }

  public batchUpdateStatusRecords(
    start: Date,
    finish: Date,
    status: VocRecordStatus,
    remarks: string,
    eventId: number
  ): Promise<VocEventStatusRecord[]> {
    return apiRequestLegacy<VocEventStatusRecord[]>(
      this.getApiRoute(`/events/${eventId}/records`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(
          {
            start,
            finish,
            status: { value: status },
            remarks,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public getRecentMessages(
    eventId: number
  ): Promise<VocGetRecentMessagesResponse> {
    return apiRequestLegacy<VocGetRecentMessagesResponse>(
      this.getApiRoute(`/events/${eventId}/message/recent`),
      this.timezoneHourAdjustment
    );
  }

  // End COMPENSATION event records tab calls

  // Start SITE event records tab calls

  public getSiteEventStatusRecords(
    siteEventId: number,
    serviceConfig: ServiceConfig
  ): Promise<VocEventStatusRecord[]> {
    return apiRequest<{ results: VocEventStatusRecord[] }>(
      `${serviceConfig.apiBaseUrl}/records/entities/${serviceConfig.entityId}/site_events/${siteEventId}/records`,
      serviceConfig
    ).then((data: any) => data.results);
  }

  public createSiteEventStatusRecord(
    start: Date,
    status: VocRecordStatus,
    remarks: string,
    siteEventId: number,
    serviceConfig: ServiceConfig
  ): Promise<VocEventStatusRecord[]> {
    return apiRequest<VocEventStatusRecord[]>(
      `${serviceConfig.apiBaseUrl}/records/entities/${serviceConfig.entityId}/site_events/${siteEventId}/records`,
      serviceConfig,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(
          {
            start,
            status: { value: status },
            remarks,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public deleteSiteEventStatusRecords(
    siteEventId: number,
    start: Date,
    finish?: Date
  ): Promise<VocEventStatusRecord[]> {
    const qs = buildQueryString({
      query: {
        start,
        finish,
      },
    });

    return apiRequestLegacy<VocEventStatusRecord[]>(
      this.getApiRoute(`/site_events/${siteEventId}/records${qs}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "DELETE",
      }
    );
  }

  public batchUpdateSiteEventStatusRecords(
    start: Date,
    finish: Date,
    status: VocRecordStatus,
    remarks: string,
    siteEventId: number,
    serviceConfig: ServiceConfig
  ): Promise<VocEventStatusRecord[]> {
    return apiRequest<VocEventStatusRecord[]>(
      `${serviceConfig.apiBaseUrl}/records/entities/${serviceConfig.entityId}/site_events/${siteEventId}/records`,
      serviceConfig,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(
          {
            start,
            finish,
            status: { value: status },
            remarks,
          },
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  // End SITE event records tab calls

  // Start event values tab calls

  public getValues(eventId: number): Promise<VocGetValuesResponse> {
    return apiRequestLegacy<VocGetValuesResponse>(
      this.getApiRoute(`/events/${eventId}/valuation`),
      this.timezoneHourAdjustment
    );
  }

  public sendValues(
    eventId: number,
    valuesUpdate: VocUpdateValuesRequest
  ): Promise<VocGetValuesResponse> {
    return apiRequestLegacy<VocGetValuesResponse>(
      this.getApiRoute(`/events/${eventId}/valuation`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(valuesUpdate, this.timezoneHourAdjustment),
      }
    );
  }

  public editValuesSubrecords(
    eventId: number,
    valuesSubrecordsUpdate: VocUpdateSubrecordsRequest
  ): Promise<VocUpdateSubrecordsResponse> {
    return apiRequestLegacy<VocUpdateSubrecordsResponse>(
      this.getApiRoute(`/events/${eventId}/valuation/records`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PUT",
        body: convertToJsonBody(
          valuesSubrecordsUpdate,
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public editSupportDocuments(
    eventId: number,
    supportDocUpdate: VocUpdateSupportDocument
  ): Promise<VocUpdateSupportDocument> {
    return apiRequestLegacy<VocUpdateSupportDocument>(
      this.getApiRoute(`/events/${eventId}/support_document`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PUT",
        body: convertToJsonBody(supportDocUpdate, this.timezoneHourAdjustment),
      }
    );
  }

  // End event values calls

  //Start comp event LOG calls

  public getLogs(eventId: number): Promise<VocGetLogs> {
    return apiRequestLegacy<VocGetLogs>(
      this.getApiRoute(`/events/${eventId}/logs`),
      this.timezoneHourAdjustment
    );
  }

  public patchUndoLog(eventId: number, logEntryId: number): Promise<any> {
    return apiRequestLegacy<any>(
      this.getApiRoute(`/events/${eventId}/logs/${logEntryId}/undo`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
      }
    );
  }

  //End comp event LOG calls

  //Start SITE event LOG calls

  public getSiteEventLogs(siteEventId: number): Promise<VocGetLogs> {
    return apiRequestLegacy<VocGetLogs>(
      this.getApiRoute(`/site_events/${siteEventId}/logs`),
      this.timezoneHourAdjustment
    );
  }

  public patchUndoSiteEventLog(
    siteEventId: number,
    logEntryId: number
  ): Promise<any> {
    return apiRequestLegacy<any>(
      this.getApiRoute(`/site_events/${siteEventId}/logs/${logEntryId}/undo`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
      }
    );
  }

  //End SITE event LOG calls

  // Start library calls

  public async getLibraries(): Promise<VocGetLibrariesResponse> {
    return apiRequestLegacy<VocGetLibrariesResponse>(
      this.getApiRoute("/libraries"),
      this.timezoneHourAdjustment
    ).then((data: any) => data);
  }

  public async getLibrary(
    library: string,
    serviceConfig: ServiceConfig
  ): Promise<VocGetDictionaryResponse> {
    return apiRequest<VocGetDictionaryResponse>(
      `${serviceConfig.apiBaseUrl}/libraries/entity/${serviceConfig.entityId}/libraries/${library}`,
      serviceConfig
    ).then((data: any) => data);
  }

  public async getLibraryUsage(library: string): Promise<VocGetLibraryUsage[]> {
    return apiRequestLegacy<VocGetLibraryUsage[]>(
      this.getApiRoute("/libraries/" + library + "/usage"),
      this.timezoneHourAdjustment
    ).then((data: any) => data);
  }

  public updateLibrary(
    library: string,
    libraryUpdate: VocUpdateLibraryRequest
  ): Promise<VocGetLibrariesResponse> {
    return apiRequestLegacy<VocGetLibrariesResponse>(
      this.getApiRoute("/libraries/" + library + "/batch"),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PUT",
        body: convertToJsonBody(libraryUpdate, this.timezoneHourAdjustment),
      }
    );
  }

  public async updateLibraryCustomSort(
    library: string,
    sortOrderUpdate: VocUpdateLibrarySortRequest
  ): Promise<VocLibraryObject[]> {
    return apiRequestLegacy<VocLibraryObject[]>(
      this.getApiRoute("/libraries/" + library + "/sort"),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(sortOrderUpdate, this.timezoneHourAdjustment),
      }
    );
  }

  public createLibraryObject(
    libraryType: VocProjectLibraryType,
    libraryObject: Partial<VocLibraryObject>
  ) {
    return apiRequestLegacy<VocLibraryObject>(
      this.getApiRoute(`/libraries/${libraryType}`),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(libraryObject, this.timezoneHourAdjustment),
      }
    );
  }

  public importExcelToLibrary(excelFile: Partial<RemarkAttachment>) {
    return apiRequestLegacy<any>( //fix type later
      this.getApiRoute("/library/import"),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(excelFile, this.timezoneHourAdjustment),
      }
    );
  }

  public async getVarianceDisplayUnit(): Promise<any> {
    return apiRequestLegacy<any>(
      `${getConfig().entityPrefix}/entities/${this.entityId}/updated`,
      this.timezoneHourAdjustment
    ).then((data) => data);
  }

  public async updateVarianceDisplayUnit(form: any): Promise<any> {
    return apiRequestLegacy<any>(
      `${getConfig().entityPrefix}/entities/${this.entityId}/updated`,
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "PATCH",
        body: convertToJsonBody(form, this.timezoneHourAdjustment),
      }
    );
  }

  // End library calls

  // Start value register

  public async getValueRegisterList(
    filterOptions: FilterOption[]
  ): Promise<VocValueRegister[]> {
    const filters = this.prepareFilterOptions(filterOptions);

    const qs = buildQueryString({
      query: {
        filters: filters.length > 0 ? filters : undefined,
      },
    });
    return apiRequestLegacy<VocValueRegister[]>(
      this.getApiRoute(`/valuations${qs}`),
      this.timezoneHourAdjustment
    );
  }

  public async updateValueRegisterList(
    valueRegisterList: Partial<VocValueRegister>[]
  ): Promise<VocValueRegister[]> {
    return apiRequestLegacy<VocValueRegister[]>(
      this.getApiRoute("/valuations"),
      this.timezoneHourAdjustment,
      {
        method: "PATCH",
        body: convertToJsonBody(
          valueRegisterList.map((item) => {
            if (!item.includedInReports) {
              item.eisA8A14 = null;
              item.eisA2A6 = null;
            }
            // remove field of VocValueRegister unused in patch call
            item.eventTypeSerial = undefined;
            item.eventTitle = undefined;
            item.compensationEventSerial = undefined;
            return item;
          }),
          this.timezoneHourAdjustment
        ),
      }
    );
  }

  public postMonthlySnapshot(req: any) {
    //type later
    return apiRequestLegacy<any>( //fix type later
      this.getApiRoute("/snapshot/monthly"),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(req, this.timezoneHourAdjustment),
      }
    );
  }

  public async getSnapshots(
    startMonthString: string,
    endMonthString: string
  ): Promise<any> {
    //type later

    const qs = buildQueryString({
      query: {
        limit: "0",
        offset: "0",
        filters: [
          {
            column: "recordDate",
            type: "Between",
            value: [startMonthString, endMonthString],
          },
        ],
      },
    });

    return apiRequestLegacy<any>( //type later
      this.getApiRoute(`/dashboard/snapshots${qs}`),
      this.timezoneHourAdjustment
    );
  }

  public async decodeValueRegisterExcelImport(
    req: any //type later
  ) {
    return apiRequestLegacy<any>( //fix type later
      this.getApiRoute("/valuations/import/decode"),
      this.timezoneHourAdjustment,
      {
        credentials: "same-origin",
        method: "POST",
        body: convertToJsonBody(req, this.timezoneHourAdjustment),
      }
    );
  }

  // End value register

  // Start ui customization

  public async getAllCustomizedScreensWithRegisterCustomFieldExtension(): Promise<{
    customizedScreens: VocCustomUi[];
    ceRegisterCustomFields: VocCustomUiFieldSchema[];
    siteEventRegisterCustomFields: VocCustomUiFieldSchema[];
  }> {
    const allScreens = apiRequestLegacy<VocCustomUi[]>(
      // this.getApiRoute("/customize/screens/all"),
      "http://localhost:3000/screens/entities/1/customize/screens/all",
      this.timezoneHourAdjustment
    );

    const ceRegisterCustomFields = apiRequestLegacy<VocCustomUiFieldSchema[]>(
      this.getApiRoute(
        `/customize/objects/type/${VocCustomUiObjectType.Event}`
      ),
      this.timezoneHourAdjustment
    );

    const siteEventRegisterCustomFields = apiRequestLegacy<
      VocCustomUiFieldSchema[]
    >(
      this.getApiRoute(
        `/customize/objects/type/${VocCustomUiObjectType.SiteEvent}`
      ),
      this.timezoneHourAdjustment
    );

    const results = await Promise.all([
      allScreens,
      ceRegisterCustomFields,
      siteEventRegisterCustomFields,
    ]);

    return {
      customizedScreens: results[0],
      siteEventRegisterCustomFields: results[1],
      ceRegisterCustomFields: results[2],
    };
  }

  // Start Variance Report

  public async getVarianceReport(): Promise<VarianceReport[]> {
    return apiRequestLegacy<VarianceReport[]>(
      this.getApiRoute("/variance/report"),
      this.timezoneHourAdjustment
    ).then((data: any) => data);
  }

  //End Variance Report

  // Start Dashboard
  public async getDashboard(): Promise<Dashboard> {
    return apiRequestLegacy<Dashboard>(
      this.getApiRoute("/dashboard/statistics"),
      this.timezoneHourAdjustment
    ).then((data: any) => data);
  }
  public async createCustomizedScreen(
    screenId: number,
    layout: VocCustomUiLayoutItem[],
    serviceConfig: ServiceConfig
  ) {
    return apiRequest<VocCustomUi>(
      this.getApiRoute(`/customize/screens/${screenId}`),
      serviceConfig,
      {
        method: "POST",
        body: convertToJsonBody(
          { layout },
          serviceConfig.timezoneHourAdjustment
        ),
      }
    );
  }
  // End Dashboard
}
