import { formatDate } from "@nexploretechnology/nxp-ui";
import { VocEvent } from "../../../../app/types";
import {
  ServiceConfig,
  apiRequest,
} from "../../../../app/utils/backend/api-request";
import { isValidUUID } from "../../../../app/utils/helper/isValidUUID";
import { getUserDisplayDateFormat } from "../../../../app/utils/mappers";
import {
  VocSummaryReferenceFile,
  VocSummaryReferenceFileForm,
  VocSummaryReferenceResponse,
} from "./reference-file-type";

export const getReferenceFiles = (
  eventId: VocEvent["eventId"],
  serviceConfig: ServiceConfig
): Promise<VocSummaryReferenceResponse> => {
  return apiRequest<VocSummaryReferenceResponse>(
    `/events/${eventId}/files`,
    serviceConfig
  );
};
export const editReferenceFiles = (
  eventId: VocEvent["eventId"],
  refFileUpdate: VocSummaryReferenceFileForm[],
  serviceConfig: ServiceConfig
): Promise<VocSummaryReferenceFile[]> => {
  const formattedBody = {
    records: refFileUpdate.map((item) => {
      const record = {
        description: item.description,
        documentDate: formatDate(item.documentDate, getUserDisplayDateFormat()),
        file: item.file.map((file) => ({
          url: file.url,
          assetId: file.assetId,
          fileName: file.fileName,
          mimeType: file.mimeType,
        })),
        link: item.link,
        comment: item.comment,
      };
      if (typeof item.id === "string" && isValidUUID(item.id)) {
        record["id"] = item.id;
      }
      return record;
    }),
  };

  return apiRequest<VocSummaryReferenceFile[]>(
    `/events/${eventId}/files`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify(formattedBody),
    }
  );
};

export const DeleteReferenceFiles = (
  eventId: VocEvent["eventId"],
  referenceFileForm: VocSummaryReferenceFileForm,
  serviceConfig: ServiceConfig
): Promise<VocSummaryReferenceFile[]> => {
  const formattedBody = {
    records: [
      {
        ...referenceFileForm,
        isDeleted: true,
      },
    ],
  };

  return apiRequest<VocSummaryReferenceFile[]>(
    `/events/${eventId}/files`,
    serviceConfig,
    {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify(formattedBody),
    }
  );
};
