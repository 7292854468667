import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Result } from "antd";
import { createUseStyles } from "react-jss";
import useAppContext from "../../hooks/useAppContext";

const useStyles = createUseStyles({
  resultWrapper: {
    "& .ant-result-icon": {
      display: "none",
    },
  },
});

const App404 = () => {
  const { serviceConfig } = useAppContext();
  const classes = useStyles();
  return (
    <Result
      className={classes.resultWrapper}
      status="404"
      title="404"
      subTitle="Page not found. Please contact system support if you cannot
          find what you are looking for."
      extra={
        <NxpButton
          type="primary"
          onClick={() => {
            window.location.replace(`${serviceConfig.qcAppBaseUrl}/entities`);
          }}
        >
          Back to QC WorkSpace
        </NxpButton>
      }
    />
  );
};

export default App404;
